/* global jQuery, $ */

// helper functions

/**
 * thanks to https://stackoverflow.com/a/3426956/2036148
 */
export function hashCode(str) { // java String#hashCode
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
}

/**
 * thanks to https://stackoverflow.com/a/3426956/2036148
 */
export function intToRGB(i) {
    const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()

    return "#" + "00000".substring(0, 6 - c.length) + c
}

/**
 * Adjust color a litle bit. Thanks to: https://stackoverflow.com/a/57401891/2036148
 * @param {string} color
 * @param {int} amount
 * @returns {String}
 */
export function adjust(color, amount) {
    return '#' + color
        .replace(/^#/, '')
        .replace(/../g, color =>
            ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16))
                .substr(-2))
}

/**
 * Python range function thanks to https://stackoverflow.com/a/8273091/2036148
 * @param {type} start
 * @param {type} stop
 * @param {type} step
 * @returns {Array}
 */
export function range(start, stop, step) {
    if (typeof stop == 'undefined') {
        // one param defined
        stop = start
        start = 0
    }

    if (typeof step == 'undefined') {
        step = 1
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return []
    }

    const result = []
    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i)
    }

    return result
}

/**
 * Splits the line thanks to https://stackoverflow.com/a/26156806/2036148
 * Better solution than other regexes.
 */
export function splitCsv(str) {
    return str.split(',').reduce((accum, curr) => {
        if (accum.isConcatting) {
            accum.soFar[accum.soFar.length - 1] += ',' + curr
        } else {
            accum.soFar.push(curr)
        }
        if (curr.split('"').length % 2 === 0) {
            accum.isConcatting = !accum.isConcatting
        }
        return accum
    }, {soFar: [], isConcatting: false}).soFar
}


/**
 * Sum array elements. If callback is given, we first map the elements to the given condition.
 * Ex: [1,2,3].sum() => 6
 *     [1,2,3].sum(x => x > 2) => 1
 * @returns {int}
 */
Object.defineProperty(Array.prototype, "sum", {
    value: function(fn = null) {
        let array = this
        if (fn) {
            array = this.map(fn)
        }
        return array.reduce((a, b) => a + b, 0)
    }
})

/**
 * Sum arrays while filling zeroes to the shorter.
 * @returns {Array}
 */
Object.defineProperty(Array.prototype, "sumTo", {
    value: function(el) {
        const total = []
        for (let i = 0; i < this.length || i < el.length; i++) {
            const a = this[i]
            const b = el[i]
            total.push((isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b))
        }
        return total
    }
})


export function number_format(s) {
    let n = String(s)
    const len = n
    let postfix
    if (len > 6) {
        n = n.slice(0, -6)
        postfix = " M"
    } else if (len > 3) {
        n = n.slice(0, -3)
        postfix = " k"
    }
    return n.replace(/(.)(?=(\d{3})+$)/g, '$1 ') + postfix
}

// String formatting function usage "string {0}".format("1") => "string 1"
Object.defineProperty(String.prototype, "format", {
    value: function() {
        const args = arguments
        return this.replace(/{(\d+)}/g, function(match, number) {
            return typeof args[number] !== 'undefined' ? args[number] : match
        })
    }
})


// sort children thanks to https://stackoverflow.com/a/17127455/2036148 (edited)
/**
 *
 * @param {type} selector Children to be sorted.
 * @param {type} attribute Their pivot attribute to be used while sorting.
 * @returns {undefined}
 */
jQuery.fn.sorting = function sorting(selector, attribute = "id") {
    function dec_sort(a, b) {
        return ($(b).attr(attribute)) < ($(a).attr(attribute)) ? 1 : -1
    }

    $(selector, this[0]).sort(dec_sort).appendTo(this[0])
}

/**
 * Generate logarithmic steps, based on https://stackoverflow.com/a/846249/2036148
 * @returns {Number}
 */
export function logslider(minp = 0, maxp = 100, minv = 100, max_v = 10000000) {
//    // position will be between 0 and 100
//    var minp = 0;
//    var maxp = 100;
//
//    // The result should be between 100 an 10000000
    minv = Math.log(minv)
    const maxv = Math.log(max_v)
    const values = []
    for (const position of range(minp, maxp + 1)) {


        // calculate adjustment factor
        const scale = (maxv - minv) / (maxp - minp)
        let v = Math.round(Math.exp(minv + scale * (position - minp)))
        //        console.log("V(position=" , position,") => ", v, values[values.length - 1]);
        if (values.length && values[values.length - 1] >= v) {
            v = values[values.length - 1] + 1
            //          console.log("Corrected to ", v);
        }
        if (v > max_v) {  // there is not enough space for so many steps, we have to shorten the step number
            if (values[values.length - 1] && values[values.length - 1] !== max_v) {
                // last step may be equal to the max
                values.push(Math.floor(max_v))
            }
            break
        }
        values.push(v)
    }
    return values
}


/**
 * Thanks to https://stackoverflow.com/a/2280117/2036148
 */
Object.defineProperty(Date.prototype, "toYMD", {
    value: function() {
        const year = String(this.getFullYear())
        let month = String(this.getMonth() + 1)
        if (month.length === 1) {
            month = "0" + month
        }
        let day = String(this.getDate())
        if (day.length === 1) {
            day = "0" + day
        }
        return year + "-" + month + "-" + day
    }
}
)

/**
 * I will never understand why vanilla JS is so poor in date format handling.
 */
Object.defineProperty(Date.prototype, "toDM", {
    value: function() {
        const month = String(this.getMonth() + 1)
        const day = String(this.getDate())
        return `${day}. ${month}.`
    }
}
)
Date.from_dmy = function(date) {
    const d = date.split(".").reverse()
    return new Date(d[0], d[1] - 1, d[2])
}


/**
 * Thanks to https://stackoverflow.com/a/30393357/2036148
 *
 * @param {type} canvasElement
 * @param {type} fileName If null, contents is returned.
 * @returns {undefined}
 */
export function exportCanvasAsPNG(canvasElement, fileName = null) {

    // var canvasElement = document.getElementById(id);

    const MIME_TYPE = "image/png"

    const imgURL = canvasElement.toDataURL(MIME_TYPE)
    if (fileName === null) {
        return imgURL
    }

    const dlLink = document.createElement('a')
    dlLink.download = fileName
    dlLink.href = imgURL
    dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':')

    document.body.appendChild(dlLink)
    dlLink.click()
    document.body.removeChild(dlLink)
}

/**
 * Thanks to https://stackoverflow.com/a/18197341/2036148
 * @param {type} filename
 * @param {type} text
 * @returns {undefined}
 */
export function downloadFile(filename, text) {
    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
}


/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 *
 * Thanks to: https://stackoverflow.com/a/22429679/2036148
 *
 * @param {string} str the input value
 * @param {boolean} [asString=false] set to true to return the hash value as
 *     8-digit hex string instead of an integer
 * @param {integer} [seed] optionally pass the hash of the previous chunk
 * @returns {integer | string}
 */
export function hashFnv32a(str, asString, seed) {
    /* jshint bitwise:false */
    let i, l,
        hval = (seed === undefined) ? 0x811c9dc5 : seed

    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i)
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24)
    }
    if (asString) {
        // Convert to 8 digit hex string
        return ("0000000" + (hval >>> 0).toString(16)).substr(-8)
    }
    return hval >>> 0
}


/**
 * Clone and scale current canvas.
 * @param {type} oldCanvas
 * @returns {Element}
 */
export function make_thumbnail(oldCanvas) {
    $("#canvas-exporter").children().remove()
    const newCanvas = $("<canvas />").appendTo($("#canvas-exporter"))[0]
    const context = newCanvas.getContext('2d')
    newCanvas.width = oldCanvas.width
    newCanvas.height = oldCanvas.height

    newCanvas.width = 400
    const scale = newCanvas.width / oldCanvas.width


    newCanvas.height = oldCanvas.height * scale

    context.scale(scale, scale)
    context.drawImage(oldCanvas, 0, 0)
    return newCanvas
}


/**
 * @param {Number[]} d Dataset.
 * @param {int} len Number >= 0 of items the result is averaged from.
 * @returns {Function} When called, yields averaged value for the given position.
 */
export function average_stream(d, len = 7) {
    return i => {
        const d_ = d.slice(Math.max(i - len + 1, 0), i + 1)
        return Math.round(d_.sum() / d_.length)
    }
}

// distinct color palette
export const palette = [
    "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00", "#b82e2e", "#316395",
    "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac",
    "#b77322", "#16d620", "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
    "#743411"
]

/**
 * Handy interval class, waiting till AJAX request finishes (won't flood server if there is a lag).
 */
export class Interval {
    /**
     *  Class for managing intervals.
     *  Auto-delaying/boosting depending on server lag.
     *  Faking intervals by timeouts.
     *
     * @param {function} fn
     * @param {int} delay
     * @param {bool} ajax_wait If true, the fn is an AJAX call.
     *          The fn will not be called again unless it calls `this.blocking = false` when AJAX is finished.
     *      You may want to include `.always(() => {this.blocking = false;})` after the AJAX call.
     *      (In 'this' should be instance of the Interval object.)
     *
     *      (Note that we preferred that.blocking setter over method unblock() because interval function
     *      can be called from other sources than this class (ex: at first run)
     *      and a non-existent method would pose a problem.)
     * @returns {Interval}
     */
    constructor(fn, delay, ajax_wait) {
        this.fn = fn
        this.delay = this._delay = delay
        this._delayed = function() {
            this.time1 = +new Date()
            this.fn.call(this)
            if (ajax_wait !== true && this.running) {
                this.start()
            }
        }.bind(this)
        this.start()
    }

    start() {
        this.stop()
        this.running = true
        this.instance = setTimeout(this._delayed, this._delay)
        return this
    }

    stop() {
        clearTimeout(this.instance)
        this.running = false
        return this
    }

    /**
     * Launch callback function now, reset and start the interval.
     * @return {Interval}
     */
    call_now() {
        this.stop()
        this._delayed()
        this.start()
        return this
    }

    /**
     * Start if stopped or vice versa.
     * @param start If defined, true to be started or vice versa.
     */
    toggle(start = null) {
        if (start === null) {
            this.toggle(!this.running)
        } else if (start) {
            this.start()
        } else {
            this.stop()
        }
        return this
    }

    set blocking(b) {
        if (b === false) {
            const rtt = +new Date() - this.time1
            if (rtt > this._delay / 3) {
                if (this._delay < this.delay * 10) {
                    this._delay += 100
                }
            } else if (rtt < this._delay / 4 && this._delay >= this.delay) {
                this._delay -= 100
            }
            if (this.running) {
                this.start()
            }
        }
    }
}