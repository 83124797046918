/* global $, gettext, Editor */
import {Figure} from './figure'
import {Territory} from './territory'
import {Calculation} from './calculation'
import {average_stream, hashCode, intToRGB} from './helper_functions'

const variables = {
    "C": gettext("confirmed"),
    "Cc": gettext("currently confirmed"),
    "R": gettext("recovered"),
    "D": gettext("death"),
    "T": gettext("confirmed"),
    "dC": gettext("newly confirmed"),
    "dCc": gettext("currently confirmed change"),
    "dR": gettext("newly recovered"),
    "dD": gettext("newly deceased"),
    "dT": gettext("newly tested"),
    "ddC": gettext("newly confirmed derivation"),
    "ddCc": gettext("currently confirmed change derivation"),
    "ddR": gettext("newly recovered derivation"),
    "ddD": gettext("newly deceased derivation"),
    "ddT": gettext("newly tested derivation"),
    "P": gettext("population"),
    "D_PAST": gettext("daily average deaths") + " 2010–2017",
    "%": "100",
    "k": "1 000",
    "M": "1 000 000",
    "X": "current X"
}
for (const v in variables) { // put spaces around variable descriptions
    variables[v] = " " + variables[v] + " "
}
const vars_all = /(D_PAST)|(ddCc)|(ddC)|(ddR)|(ddD)|(ddT)|(dCc)|(dC)|(dR)|(dD)|(dT)|(Cc)|[CRDTP%kMX]/g

function NaNException() {
}

export class Equation {
    // because it imposes stupid indentation
    constructor(
        expression = "", active = true, figure_id = null, y_axis = 1,
        checked_names = [], starred_names = [], type = Equation.TYPE_LINE,
        aggregate = false
    ) {
        /**
         * @type {Territory[]} chosen territories to be processed
         */
        this.checked = checked_names.map(name => Territory.get_by_name(name))
        // console.log("Equation", this.checked, checked_names);
        /**
         * @type {Territory[]} chosen
         */
        this.starred = starred_names.map(
            name => Territory.get_by_name(name, false) || this._get_aggregate_territory(true))
        this.expression // current function
        this.hash // small hash of the function, used to modify equation colour a little bit
        this.set_expression(expression)
        this._valid = null // check if this expression is valid
        this.active = active
        this.$element = null

        Equation.equations.push(this)

        this.id = Equation.equations.length
        this.set_figure(Figure.get(figure_id || 1))
        this.y_axis = y_axis


        this._type = type // int; line, bar, stacked by equation, territory
        this.aggregate = aggregate

        this.build_html()
    }

    static serialize() {
        Editor.setup["equation"] = Equation.current.id
        return Equation.equations.map(p => {
            return [p.expression,
                p.active,
                p.figure.id,
                p.y_axis,
                p.checked.map(t => t.get_name()),
                p.starred.map(t => t.get_name()),
                p._type,
                p.aggregate
            ]
        })
    }

    static deserialize(data) {
        Equation.equations = []
        data.forEach((d) => new Equation(...d))
        if (Equation.equations.length) {
            (Equation.equations[Editor.setup["equation"] - 1] || Equation.equations[0]).focus()
        }
    }

    set_figure(figure) {
        if (this.figure) {
            this.figure.remove_equation(this)
        }
        /**
         * @type {Figure}
         */
        this.figure = figure.add_equation(this)
    }

    get valid() {
        return this._valid
    }

    set valid(val) {
        if (this.$element) {
            this.$element.toggleClass("invalid", val === false)
        }
        this._valid = val
    }

    /**
     *
     * @param {bool} clean Clean the old current equation-
     * @returns {Equation}
     */
    focus(clean = true) {
        const cp = Equation.current
        if (clean && cp && cp !== this) { // kick out the old equation
            if (cp.checked && cp.expression) { // show only if it is worthy
                cp.$element.removeClass("edited")
                // cp.$element.show();
            } else {
                cp.remove(false)
            }
        }
        Territory.equation = Equation.current = this
        Editor.$equation.val(this.expression)
        $("#equation-type").data("ionRangeSlider").update({from: this.type})
        if (this.$element) {
            this.$element.addClass("edited")
        }
        $("#sum-territories").prop("checked", this.aggregate)
        // $("#percentage").prop("checked", this.percentage);
        this.figure.focus()
        this.refresh_html()
        return this
    }

    dom_setup() {
        Object.assign(Equation.current, {
            type: Editor.setup["equation-type"],
            aggregate: Editor.setup["sum-territories"]
            // percentage: Editor.setup["percentage"]
        })
        delete Editor.setup["equation-type"]
        delete Editor.setup["sum-territories"]
        // delete Editor.setup["percentage"];
        this.refresh_html()
    }

    remove(focus_next = true) {
        const without_me = Equation.equations.filter(p => p !== this)

        if (this === Equation.current) {
            if (without_me.length) {
                if (focus_next) {
                    without_me[0].focus(false)
                }
            } else {
                // we cannot remove the last equation, just clear the text
                Editor.$equation.val("").focus()
                this.set_expression("")
                this.refresh_html()
                return
            }
        }

        this.figure.remove_equation(this)
        Equation.equations = without_me
        let i = 0
        Equation.equations.forEach(e => {
            e.id = ++i
        }) // renumber equation IDs
        this.$element.hide(500, function() {
            $(this).remove()
        })
    }

    get type() {
        if (this.figure.type === Figure.TYPE_LOG_DATASET) {
            return Equation.TYPE_LINE
        }
        if (this.figure.type === Figure.TYPE_PERCENT_TIME && this._type === Figure.TYPE_LINEAR_TIME) {
            // since line type does not make sense while Figure.TYPE_PERCENT_TIME, display stacked
            return Equation.TYPE_STACKED_EQUATION
        }
        return this._type
    }

    set type(v) {
        this._type = v
    }

    set_expression(expression) {
        if (expression !== null) {
            // XX put XSS protection here rather than when output
            this.expression = expression
            // 'C' is the default equation, let the colour be as I am used to
            this.hash = (expression === "C") ? 0 : hashCode(expression) % 20 * 5

            this._expression = this.expression
                .replace(/(dNC)|(dNR)|(dND)|(dNT)|(NC)|(ND)|(NR)|(NT)/g, m => m.replace("N", "d")) // backward compat.
                .replace(/\s/g, "") // strip spaces
                .replace(vars_all, m => "(" + m + ")") // put every variable in parenthesis
                .replace(/\)([((\d])/g, m => ")*" + m[1]) // '(k)100' => '(k)*100', '(k)(k)' => '(k)*(k)'

            this._title = this._expression
                .replace(/\([A-Za-z%]+\)/g, m => m.substr(1, m.length - 2))  // strip parenthesis around expressions
                .replace(vars_all, m => variables[m]) // put the variable descriptions instead of the names
                .trim()
        }
    }

    /**
     * Assure the equation is in the equation stack
     */
    build_html() {
        const s = '<input type="number" min="1" class="equation-figure" value="' + this.figure.id
            + '" title="' + gettext("If you change the number, you place the equation on a different figure.") + '"/>'
        const t = '<input type="number" min="1" max="5" class="y-axis" value="' + this.y_axis
            + '" title="' + gettext("Independent y-axis scale") + '"/>'
        this.$element = $("<div><span class=name></span>" + s + t
            + "<span class='shown btn btn-light'>👁</span><span class='remove btn btn-light'>×</span></div>")
            .data("equation", this)
            .appendTo($("#equation-stack"))
        this.refresh_html()
    }

    icon() {
        switch (this.type) {
            case Equation.TYPE_BAR:
                return "<span title='bars displayed'> ❘ </span>"
            case Equation.TYPE_STACKED_EQUATION:
                return "<span title='stacked by equation'> ☰ "
            case Equation.TYPE_STACKED_TERRITORY:
                return "<span title='stacked by territory'> 🏳 </span>"
            case Equation.TYPE_LINE:
            default:
                return ""
        }
    }

    refresh_html(expression = null) {
        this.set_expression(expression)

        const name = document.createTextNode(this.expression).textContent + this.icon()
            + " (" + (this.aggregate ? "∑ " : "") + this.checked.length + " " + gettext("countries") + ")"
        $("> .name", this.$element).html(name) // attention to XSS, `this.expression` is unsafe
        if (this.active) {
            this.$element.addClass("active")
        }
        if (this.valid === false) {
            this.$element.addClass("invalid")
        }

        // allow multiple figures
        // console.log("SHOW EQUATION FIGURE?", Editor.setup["equation-figure"]);
        $(".equation-figure", this.$element).toggle(Boolean(parseInt(Editor.setup["equation-figure-switch"])))
        $(".y-axis", this.$element).toggle(Boolean(parseInt(Editor.setup["y-axis-independency-switch"])))

        // hide remove buttons if there is last equation
        $(".remove", $("#equation-stack")).toggle(Equation.equations.length > 1)
    }

    /**
     * Localised name
     * @param highlight
     * @return {string}
     */
    get_name(highlight = false) {
        // XX starred first
        let s
        const n = this.checked.length
        if (n < 4) {
            s = this.checked.map(t => t.get_label()).join(", ")
        } else {
            s = n + " " + gettext("territories")
        }
        if (highlight) {
            s = " *** " + s + " ***"
        }
        return s
    }

    /**
     * Used in Figure title.
     * @return {string}
     */
    get_title() {
        let t = this.get_name() + " (" + this._title + ")"
        if (this.aggregate) {
            t = gettext("Sum of") + " " + t
        }
        return t
    }

    /**
     *
     * @param init_star If true, temporary territory is created starred.
     * @return {Territory}
     * @private
     */
    _get_aggregate_territory(init_star = false) {
        const territory = new Territory(gettext("Sum of"), this.checked)
        if (!(this._territory && this._territory.dom_id === territory.dom_id)) {
            this._territory = territory // aggregated territory from last time, will save some performance
            this.checked.forEach(t => {
                if (t.ancestors.filter(x => this.checked.indexOf(x) > -1).length) {
                    // one of the parents is here too; do not count Czechia if Europe (its super-set) is present
                    return
                }

                territory.add_data_all(t.data)
                territory.population += t.population
                territory.death_avg += t.death_avg
            })
            if (init_star) {
                territory.is_starred = true
            }
        }
        return this._territory
    }

    /**
     * Return territory or temporary aggregated territory.
     * @return {Territory[]}
     */
    get_territories() {
        if (this.aggregate) {
            return [this._get_aggregate_territory()]
        }
        return this.checked
    }

    /**
     * @param {Territory} territory
     */
    territory_info(territory = null) {
        return [
            this.starred.indexOf(territory) > -1,
            this.id + "" + territory.dom_id]
    }

    /**
     *
     * @param {Territory} territory
     * @param {Figure} figure
     * @return {string}
     */
    territory_label(territory, figure) {
        return this.expression.indexOf("X") > -1 ?
            this.expression :
            territory.get_label(true) + (figure.get_active_equations().length > 1 ? " (" + this.expression + ")" : "")
    }

    /**
     * Get expression color
     * @returns {string}
     */
    color() {
        switch (this.expression) {
            case "C":
                return "#0000FF"
            case "R":
                return "#29AC76"
            case "D":
                return "#A11E00"
            default:
                return intToRGB(hashCode((this.hash + "").repeat(10)))
        }
    }

    /**
     * Toggle star.
     * @param {Territory} territory
     * @returns {boolean} has star
     */
    set_star(territory) {
        return territory.set_star(null, this)
    }

    /**
     * Get current equation data.
     * @param {Equation[]} equations
     * @return {[[Territory, Equation, Array, Integer],[Integer, Integer, Integer], String]}
     *  XX This is not a valid JSDoc, we are waiting for https://github.com/jsdoc/jsdoc/issues/1073
     *      to allow document array indexes.
     */
    // eslint-disable-next-line complexity
    static get_data(equations = []) {
        const title = []
        const result = []
        const outbreak_population = Editor.setup["outbreak-mode"] ? 1 : 0
        const outbreak_threshold = Editor.setup["outbreak-on"] ? parseInt(Editor.setup["outbreak-value"]) : 0
        /**
         * @type {(number)[]} min Y, max Y, outbreak population percentile, min X
         */
        let boundaries = [Number.POSITIVE_INFINITY, 0, 0, Number.POSITIVE_INFINITY]
        for (const p of equations) {
            p.valid = null
            for (const t of p.get_territories()) {
                const chart_data = []
                let day_count, day_start, day_end, outbreak_start
                let ignore = true

                const averagable = (data) => {
                    if (Editor.setup["average"]) {
                        return average_stream(data, 7)
                    } else {
                        return v => data[v]
                    }
                }
                // the length of C must be the same as of Territory.header
                const [C, R, D, T] = ["confirmed", "recovered", "deaths", "tested"].map(d => averagable(t.data[d]))

                let last_vars = new Proxy({}, {get: () => 0}) // returns zeroes by default
                for (let j = 0; j < Territory.header.length; j++) {
                    // append the data starting with threshold
                    // (while threshold can be number of confirmed cases totally or confirmed cases in population
                    if (ignore) { // we have not yet passed outbreak
                        const c = C(j)
                        if (!outbreak_threshold // there is no outbreak
                            || // outbreak determined by:
                            ((outbreak_population && c >= outbreak_threshold * t.population / 100000) // population
                                || (!outbreak_population && c >= outbreak_threshold) // constant number of cases
                            )) {
                            outbreak_start = j
                            ignore = false

                            day_start = Editor.setup["day-range"][0]
                            day_end = Editor.setup["single-day"] ? day_start : Editor.setup["day-range"][1]

                            // forward to day_start (skip non-displayed days)
                            // and - 3 day backwards to determine second derivation like `ddC`
                            const shift = day_start - 3
                            j += shift
                            day_count = shift - 1
                            if (j < 0) { // dates at point j == -1 do not exist, shift to first dates we have
                                day_count += -j
                                j = 0
                            }
                        }
                    }
                    if (!ignore) {
                        // assign variables
                        const vars = {
                            "C": C(j),
                            "R": R(j),
                            "D": D(j),
                            "T": T(j),
                            "P": t.population,
                            "%": 100,
                            "k": 1000,
                            "M": 1000000,
                            "D_PAST": t.death_avg,
                            "X": ++day_count
                        }
                        vars["Cc"] = vars["C"] - vars["R"] - vars["D"]

                        // XX cleaner code but maybe slightly slower
                        // ;["C", "R", "D", "T", "Cc"].forEach(d => {
                        //     vars["d" + d] = vars["N" + d] = vars[d] - last_vars[d]
                        //     vars["dd" + d] = vars["dN" + d] = vars["d"+d] - last_vars["d"+d]
                        // })


                        vars["dC"] = vars["NC"] = vars["C"] - last_vars["C"]
                        vars["dR"] = vars["NR"] = vars["R"] - last_vars["R"]
                        vars["dD"] = vars["ND"] = vars["D"] - last_vars["D"]
                        vars["dT"] = vars["NT"] = vars["T"] - last_vars["T"]
                        vars["dCc"] = vars["Cc"] - last_vars["Cc"]

                        vars["ddC"] = vars["dNC"] = vars["dC"] - last_vars["dC"]
                        vars["ddR"] = vars["dNR"] = vars["dR"] - last_vars["dR"]
                        vars["ddD"] = vars["dND"] = vars["dD"] - last_vars["dD"]
                        vars["ddT"] = vars["dNT"] = vars["dT"] - last_vars["dT"]
                        vars["ddCc"] = vars["dCc"] - last_vars["dCc"]

                        last_vars = vars
                        if (day_count < day_start) {
                            // we are just loading initial values, so that initial day (outbreak start)
                            // can calculate second derivation (ddC)
                            continue
                        } else if (day_count > day_end) {
                            break  // current day is over allowed range
                        }


                        // replace variables with numerals
                        let result
                        try {
                            result = p.express(vars)
                        } catch (e) {
                            if (e instanceof NaNException) {
                                // any of the variables was replaced by NaN
                                // ex: data missing for this day
                                continue
                            } else {
                                throw e // something other happened
                            }
                        }

                        // perform calculation
                        result = Calculation.calculate(result)
                        $("#equation-alert").hide()
                        if (typeof (result) === "string") { // error encountered
                            if (p._expression.trim()) {
                                $("#equation-alert").show().html(`
<b>${gettext("Use one of the following variables")}: <code>${Object.keys(variables).join(" ")}</code></b>
(${document.createTextNode(result).textContent})`) // `result` XSS protection
                            }
                            p.valid = false
                            break
                        } else {// calculation succeeded
                            // recount boundaries
                            boundaries = [
                                Math.min(result, boundaries[0]),
                                Math.max(result, boundaries[1]),
                                Math.max(t.population, boundaries[2]),
                                Math.min(vars[Editor.setup["x-axis"]], boundaries[3])
                            ]
                            // store data
                            if (p.figure.type === Figure.TYPE_LOG_DATASET) {
                                chart_data.push({x: vars[Editor.setup["x-axis"]], y: result})
                            } else {
                                chart_data.push(result)
                            }
                        }
                    }
                }
                // if aggregated, we do not tell outbreak_start since every agg. country has different
                result.push([p, t, chart_data, p.aggregate ? null : outbreak_start])
            }
            if (p.valid === false) {
                break
            } else {
                p.valid = true
            }
            title.push(p.get_title())
        }
        // console.log('607: result(): ', result.map(r => r[2].length))
        return [result, boundaries, title]  // result: [equation, territory, data, outbreak_start]
    }

    /**
     * Replace variables in the expression string by vars.
     * (Note that in JS isNaN("1000") === false and isNaN("1000+1") === true.
     * So when resolving an expression we have to compare every replacement apart.)
     * @param vars
     * @returns {String}
     */
    express(vars) {
        return this._expression
            .replace(vars_all, m => {
                const v = vars[m]
                if (isNaN(v)) {
                    throw new NaNException()
                }
                return v
            })
    }
}

/**
 *
 * @type Equation
 */
Equation.current = null
/**
 *
 * @type {Equation[]}
 */
Equation.equations = []

Equation.TYPE_LINE = 0
Equation.TYPE_BAR = 1
Equation.TYPE_STACKED_EQUATION = 2
Equation.TYPE_STACKED_TERRITORY = 3
Equation.TYPE = [
    gettext("line"),
    gettext("bar"),
    gettext("stacked by equation"),
    gettext("stacked by territory")
]
