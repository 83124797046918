/**
 * Territory class

 * province – NY
 * country – CZ, UK
 * region – USA (region without mainland behaves the same as a country)
 * region with mainland – UK (Region)


 */
/* global $, gettext, Editor */
import {Equation} from './equation'
import {territories} from './mappings'
import {number_format, splitCsv} from './helper_functions'

let counter = 0

export class Territory {

    /**
     *
     * @param {string} name
     * @param {string|Territory[]} type Ex: Territory.COUNTRY or array of territories (will become Territory.TEMPORARY
     */
    constructor(name, type) {

        let label = null
        if (Array.isArray(type)) {
            this.dom_id = "a" + type.map(t => t.id).join("_")
            name = "sum"
            label = type.slice(0, 3).map(t => gettext(t.get_name())).join(", ")
            if (type.length > 3) {
                label += "..."
            }
            type = Territory.TEMPORARY
        } else {
            this.id = ++counter
            this.dom_id = "t" + (this.id)
            Territory.id_list.push(this)
            Territory[type][name] = this // store to ex: Territory.states
        }

        this.name = name // english standardized name or "sum" for
        this.label = label || gettext(name) // localised name
        this.type = type
        // this.toggled = false
        this.shown = type === Territory.CONTINENT  // by default, every countries are hidden
        this.is_starred = false
        this.is_eye_on = null
        this.data = {"confirmed": [], "deaths": [], "recovered": [], "tested": []}
        this.population = null
        this.death_avg = null

        /** @type {Territory[]} */
        this.parents = []
        /** @type {Territory[]} */
        this.children = []
        this.mainland = null // COUNTRY United Kingdom has its mainland STATE United Kingdom
    }

    /**
     * Add this data to the territory and possibly to all of its parents.
     * @param {Array} data
     * @param {string} type
     * @param propagate If true, we add data to all of our parents.
     * @returns {undefined}
     */
    add_data(data, type, propagate = true) {
        data = data.map(d => parseInt(d))
        if (!this.data[type].length) {
            this.data[type] = data
        } else {
            this.data[type] = this.data[type].map((num, idx) => {
                return num + data[idx]
            })
        }

        if (propagate) {
            this.parents.forEach(p => p.add_data(data, type))
        }
    }

    add_data_all(data) {
        ["confirmed", "deaths", "recovered", "tested"].forEach(type => {
            if (data[type].length) {
                this.add_data(data[type], type)
            }
        })
    }

    /**
     * @returns {String} Name of the territory.
     */
    get_name() {
        return this.name
    }

    /**
     * @returns {String} Label of the territory. (Possible quotes around the name stripped.)
     */
    get_label(hightlightable = false) {
        let label = this.label
        if (this.type === Territory.REGION && this.mainland) {
            label += " (" + gettext("Region") + ")"
        }
        if (hightlightable && this.is_starred) {
            label = " *** " + label + "***"
        }
        return label
    }

    get is_active() {
        return this.equation.checked.indexOf(this) > -1
    }

    set_active(check = true) {
        if (check === null) {
            check = !this.is_active
        }
        this.$activate_button.prop("checked", check)
        if (!Territory.loading_freeze) {
            if (check) {
                this.equation.checked.push(this)
            } else {
                this.equation.checked = this.equation.checked.filter(e => e !== this) // remove from chosens
            }
            if (!Territory.parent_freeze) {
                this.parents.forEach(p => p.some_children_active(check))
                Equation.current.refresh_html()
            }
        }
        return this
    }

    static uncheck_all() {
        Territory.parent_freeze = true
        Territory.id_list.forEach(t => t.set_active(false))
        Territory.parent_freeze = false
        Editor.refresh()
    }

    /**
     * Child tells its parent that one of its children becomes (not) active.
     * @param {boolean} set
     * @returns {undefined}
     */
    some_children_active(set = true) {
        let off = null
        if (!set) {
            off = true
        } else if (this.children.every(ch => ch.$activate_button.prop("checked"))) {
            // XX if its a performance issue, may be delayed
            off = false
        }
        if (off !== null) {
            this.$child_activate_button.toggleClass("off", off)
        }
    }

    /**
     * Check if there is at least one hidden child.
     * @returns {boolean}
     */
    any_hidden_child() {
        return this.children.some(ch => !ch.shown)
    }

    /**
     *
     * @param {Boolean} is_set If null, star toggled.
     * @param {Equation} equation
     * @returns {boolean}
     */
    set_star(is_set = null, equation = null) {
        if (equation === null) {
            equation = this.equation
        }

        const el = this
        if (is_set === null) {
            is_set = !(equation.starred.indexOf(el) > -1)
        }
        $("> span:eq(1)", this.$element).toggleClass("off", !is_set)
        if (!Territory.loading_freeze) {
            if (is_set) {
                equation.starred.push(el)
            } else {
                equation.starred = equation.starred.filter(e => e !== el)
            }
        }
        this.is_starred = is_set
        return is_set
    }

    /**
     * Hide the territory's descendants and the territory itself if it is not checked ( = active)
     *  and all of the parents tell this should be hidden.
     * Example: United Kingdom Region tells its children to hide but United Kingdom Country remains visible
     *  if other European countries remain (Europe eye is on and Europe is shown).
     * @returns {boolean}
     */
    hide() {
        let just_hidden = false

        if (this.shown && !this.is_active && !this.parents.some(p => p.is_eye_on && p.shown)) {
            // hide only if it is not active
            // and if there are no visible parent with its eye icon in the on state
            this.shown = false
            this.$element.hide(Territory.TOGGLE_DURATION)
            just_hidden = true
            if (this.type === Territory.STATE) {
                Territory.states_shown(-1)
            }
        }

        const eye_on = this.is_eye_on
        this.is_eye_on = false
        if (this.children.sum(ch => ch.hide())) {
            this.eye(false)
            return true
        } else {
            this.is_eye_on = eye_on
        }

        return just_hidden
    }

    /**
     * Show the territory. Do not show its descendants.
     * @returns {undefined}
     */
    show() {
        if (this.type === Territory.STATE && this.shown === false) {
            Territory.states_shown(1)
        }
        this.shown = true
        this.$element.show(Territory.TOGGLE_DURATION).find("span:eq(3)")
        this.eye()
    }

    get $element() {
        return $("#" + this.dom_id)
    }

    get $child_activate_button() {
        return $("> span:eq(3)", this.$element)
    }

    get $activate_button() {
        return $("> input", this.$element)
    }

    get equation() {
        return Equation.current
    }

    static set equation(equation) {
        Territory._states_shown = 0
        Territory.loading_freeze = true
        Territory.id_list.forEach(t => {
            const active = equation.checked.indexOf(t) > -1
            const star = equation.starred.indexOf(t) > -1
            t.set_active(active)
            t.set_star(star)
            if (active || star) {
                t.show()
            }
        })
        Territory.loading_freeze = false
    }

    /**
     * @return {[string]}
     */
    get_html() {
        const v = this.shown ? "" : " style='display:none'"
        const disabled = Object.values(this.data).filter(d => d !== "0").length ? "" : " (" + gettext("zero") + ")"
        const s = [`<div ${v}id='${this.dom_id}' data-sort='${this.get_label()}'>`]
        s.push("<input type=checkbox />")
        s.push("<span>" + this.get_label() + "</span>" + disabled)
        s.push(" <span class='off'>☆</span> ")
        if (this.children.length) {
            s.push("<span class='off'>👁</span>") // XX save to hash
            s.push(" <span class='off'>✓</span> ")
        }
        if (this.death_avg) {
            s.push(`<i title='${this.death_avg} ${gettext("daily average deaths")}'>†</i>`)
        }
        if (this.type !== Territory.CONTINENT) {
            // show special data
            // but hide for continents because since only few countries have tested date,
            // this would give a false impression we fetch data for majority of countries
            if (this.data["tested"].length) {
                s.push(`<i title='${gettext("tested")}'>&#129514;</i>`) // XX add tested-data methodology when available
            }
            // XX possibility to add hospitalised data
            // if (this.data["hospitalised"].length) {
            //     s.push(`<i title='${gettext("hospitalised")}'>⚕</i>`)
            // }
        }
        if (this.population) {
            s.push(" <i>(" + number_format(this.population) + ")</i>")
        }
        s.push("</div>")
        return s
    }

    /**
     * Add population number to this and its parents.
     * @param {int} population
     * @param {int} death_avg
     * @param {String} color
     * @returns {undefined}
     */
    add_info(population, death_avg, color = null) {
        this.population += population
        this.death_avg += death_avg
        this.color = color
        this.parents.forEach(p => p.add_info(population, death_avg))
    }

    /**
     * Add this territory as a child and add its population.
     * @param {Territory} t
     * @param {boolean} propagate_data If true, we add data of the child to our data.
     * @returns {Territory}
     */
    add_child(t, propagate_data = true) {
        if (this.children.indexOf(t) === -1) { // not already added
            this.children.push(t)
            this.population += t.population
            this.death_avg += t.death_avg
            t.parents.push(this)
        }
        if (propagate_data) {
            this.add_data_all(t.data)
        }
        return this
    }

    /**
     * Toggle the eye icon shown or hidden.
     * If not set, it will be in the on=shown state only if there is no hidden child.
     * @param {Boolean} set
     */
    eye(set = null) {
        if (set === null) {
            set = !this.any_hidden_child()
        }
        $("> span:eq(2)", this.$element).toggleClass("off", !set)
    }

    /**
     * XIf there any visible and non-active children, hide it, else show all.
     * If there any hidden child, show them all. Else hide all non-active children and their children.
     * @returns {undefined}
     */
    toggle_children_visibility() {
        if (this.any_hidden_child()) {
            this.is_eye_on = true
            this.children.forEach(ch => ch.show())
        } else {
            // no child was hidden, they are all active or have another eye-on parent
            this.is_eye_on = false
            // noinspection RedundantIfStatementJS
            // note that you cannot simplify following expression since children are using parent.is_eye_on
            if (!this.children.sum(ch => ch.hide())) {
                // no child was hidden, they are all active or have another eye-on parent
                this.is_eye_on = true
            }
        }
        this.eye(this.is_eye_on)
    }

    /**
     * XIf there any checked children, uncheck them all, else check all.
     * If there is any unchecked child, check them all, else uncheck all.
     * @returns {undefined}
     */
    toggle_children_checked() {
        Territory.parent_freeze = true
        const any_unchecked_check_all = this.children.some((child) => !child.is_active)
        this.children.forEach((child) => child.set_active(any_unchecked_check_all))
        $("> span:eq(3)", this.$element).toggleClass("off", !any_unchecked_check_all)
        Territory.parent_freeze = false
        if (!this.is_eye_on) {
            // XX small bug, when reloading or changing equation,
            // even if all children are checked, the check box is not yellow
            // hence if we click on that, we want to check them all but instead we uncheck them all and hide them
            this.toggle_children_visibility()
        }

        Equation.current.refresh_html()
    }

    /**
     * Hide States column when empty.
     * If there is no visible state left, hide the column to gain more space for countries.
     * @param {Integer} add Plus/minus one to shown states. We check its number and if 0/1, we hide/show the column.
     *      If null, we just check the number, we does not make change (at init).
     */
    static states_shown(add = null) {
        if (add !== null) {
            Territory._states_shown += add
        }

        const show = Territory._states_shown === 1 ? true : (Territory._states_shown === 0 ? false : null)
        if (show) {
            this._states_toggle() // shows immediately, before appearing state animation runs
        } else if (show === false) {
            // hide in a while when all disappearing state animations end
            Territory.states_timeout = setTimeout(this._states_toggle, Territory.TOGGLE_DURATION + 100)
        }
    }

    static _states_toggle(show) {
        clearTimeout(Territory.states_timeout) // correct behaviour when being shown after hiding timeout run
        $("#territory-options > .row:first-child > div:first-child").toggle(show)
        $("#territories > div:first-child").toggle(show)
        $("#territories > #countries-cell").toggleClass("three-col", !show)
    }

    /**
     * @return {Territory[]} All ancestors (parents and their parents).
     */
    get ancestors() {
        return this.parents.concat(this.parents.map(p => p.parents))
    }

    /**
     *
     * @param {string} name
     * @param {string} type
     * @returns {Territory}
     */
    static get(name, type) {
        // strip possible CSV quotes: "Korea, South" -> Korea, South
        if (name.substring && name.substring(0, 1) === '"' && name.substring(-1, 1) === '"') {
            name = name.substr(1, name.length - 2)
        }

        const key = name + "_" + type
        if (!(key in Territory.territories)) {
            Territory.territories[key] = new Territory(name, type)
        }

        // X Territory.territories[key].population += population;
        // X Territory.territories[key].death_avg += death_avg;
        return Territory.territories[key]
    }

    /**
     * Get by extended name (ex: United Kingdom (Region))
     * @param {string} name
     * @param strict If true and territory not found, prints console error.
     * @returns {Territory|boolean}
     */
    static get_by_name(name, strict = true) {
        for (const o of Territory.id_list) {
            if (o.get_name() === name) {
                return o
            }
        }
        if (strict) {
            throw `Territory ${name} not found.`
        }
        return false
    }

    /**
     * Get territory by its id.
     * @param {String} id (Territory.dom_id), ex: t15 -> will return 15th territory
     * @returns {Territory}
     */
    static get_by_dom_id(id) {
        return Territory.id_list[parseInt(id.substr(1)) - 1]
    }

    /** Create Territory objects.
     * @param {string} csv Raw data from github
     * @param {string} type
     */
    static build(csv, type) {
        const lines = csv.split("\n")

        const headers = lines[0].split(",").slice(4) // XX add dates or something
        if (headers.length && headers[headers.length - 1] === "") {
            headers.slice(0, -1) // strip last empty field
        }
        // data sheets have sometimes different length but start at the same day. We pick the longest.
        if (headers.length > Territory.header.length) {
            Territory.header = headers.map(h => new Date(h))
        }

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i]) {
                continue
            }
            const line = splitCsv(lines[i])
            const data = line.slice(4)
            if (data.length && data[data.length - 1] === "") {
                data.slice(0, -1) // strip last empty field
            }

            let t
            if (!line[0]) { // this is country
                t = Territory.get(line[1], Territory.COUNTRY)
                t.add_data(data, type)
            } else { // this is state & region
                /**
                 * true: add data to region # default, Bermuda to UK region, Hubei to China
                 * false: do not add data to country # Wales to UK mainland country, NY to USA
                 * @type {boolean}
                 */
                const mode = true // XX forward compatible to accept other sources than Hopkins
                t = Territory.get(line[1], mode ? Territory.REGION : Territory.COUNTRY)
                const ch = Territory.get(line[0], Territory.STATE)
                t.add_child(ch, false)
                ch.add_data(data, type, mode)
            }
        }
    }

    /**
     *
     * @param csv Example:
     // eslint-disable-next-line max-len
     id,datum,kraj_nuts_kod,kraj_nazev,kumulativni_pocet_testu,pocet_PCR_testy,pocet_AG_testy,typologie_test_indik_diagnosticka,typologie_test_indik_epidemiologicka,typologie_test_indik_preventivni,typologie_test_indik_ostatni,pozit_typologie_test_indik_diagnosticka,pozit_typologie_test_indik_epidemiologicka,pozit_typologie_test_indik_preventivni,pozit_typologie_test_indik_ostatni
     f16dfffd-416c-4312-b9ed-6703cfb33baf,2020-09-01,CZ010,"Hlavní město Praha",2708,2708,0,692,358,122,1536,34,12,2,53
     2efdb40a-9e5d-43e7-a7f2-77a1cba01b69,2020-09-01,CZ020,"Středočeský kraj",1598,1598,0,523,172,60,843,24,4,2,31
     */
    static build_cz_tests(csv) {
        const lines = csv.split("\n")
        const t = Territory.get("Czechia", Territory.COUNTRY)
        const data = {}

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i]) {
                continue
            }
            const line = splitCsv(lines[i])
            const date = new Date(line[1].split("-"))
            data[date] = (data[date] || 0) + line[4] * 1 // ex: data["2020-09-01"] += 2708
        }

        // get an array of an increasing cumulative sum for each day
        let cumulative = 0
        t.add_data(Territory.header.map(date => {
            cumulative += data[date] || 0
            return cumulative
        }), "tested")
    }

    // // Raw data from MVCR API v1
    // static build_json(json) {
    //     const data_cz = json.data.map(d => d["testy-celkem"])
    //
    //     const start = Date.from_dmy(json.data[0]["datum"])
    //     const i = Territory.header.findIndex(h => !(h < start)) // JS cannot simple tell if dates are equal
    //     if (i === -1) {
    //         throw "Cannot bind czech tests date."
    //     }
    //     Territory.get("Czechia", Territory.COUNTRY).add_data((new Array(i).fill(0)).concat(data_cz), "tested")
    // }

    /**
     *
     * Call this when all sources were built.
     * 1. This connects REGION to mainland COUNTRIES.
     *      If found, it has "(Region)" appended to its label.
     *      If no mainland is found, the REGION behaves exactly the same as any other COUNTRY.
     * 2. Map continents and population to REGION/COUNTRIES.
     *
     * Ex: In the USA REGION: NY STATE, there is no COUNTRY USA.
     *     In the UK REGION: UK COUNTRY (mainland), Isle of Man STATE
     *
     * @returns {undefined}
     */
    static finalize() {
        // connect regions to its mainland countries
        Object.values(Territory.regions).forEach(r => {
            const mainland = Territory.countries[r.name]

            if (mainland) { // mainland UK Country exists for UK Region (but no US Country)
                r.add_child(mainland)
                r.mainland = mainland
            }
        })

        // build continents
        territories.forEach(el => {
            const continent = Territory.get(el.continent, Territory.CONTINENT)
            continent.label = el.label
            el.countries.forEach(el => {
                const region = Territory.regions[el.name]
                const country = Territory.countries[el.name]
                const territory = country || region

                // check if country has been found in the datasheets
                if (territory) {
                    territory.label = el.label
                    if (el.pop) {
                        // add population preferably to the country
                        // that will propagate it to its region if its mainland (UK)
                        // or to the region if no mainland (USA)
                        territory.add_info(el.pop, el.death_avg, el.color)
                    }

                    if (region && country) {
                        region.label = el.label
                        // add the region to the continent and add its data
                        continent.add_child(region)
                        // will not add its data to the continent, already done by the region
                        continent.add_child(country, false)
                    } else {
                        continent.add_child(territory) // add the data to the continent
                    }
                }
            })
            if (Territory.world !== continent) {
                Territory.world.add_child(continent) // add continent data to the world
            }
        })

        // other continent
        Object.values(Territory.territories)
            .filter(t => !t.parents.length && t.type !== Territory.CONTINENT)
            .forEach(t => Territory.default_territory.add_child(t))
    }
}

// static attributes ("static" keyword not yet supported in FF)

Territory.STATE = "states"
Territory.COUNTRY = "countries"
Territory.REGION = "regions"
Territory.CONTINENT = "continents"
Territory.TEMPORARY = "temporary" // used for aggregated territories

Territory.territories = {} // [name_type => object]
/**
 * @type {Territory[]}
 */
Territory.id_list = [] // sorted by id
/**
 * @type {Territory[]}
 */
Territory.states = {} // ex: Isle of Man, Texas
Territory.countries = {} // ex: Czechia, United Kingdom (mainland w/o provinces), not USA
Territory.regions = {} // ex: United Kingdom, USA, China
Territory.continents = {}
Territory.parent_freeze = false
Territory.loading_freeze = false
Territory.header = []

/**
 *
 * @type {Territory} here comes countries that are not stated in mapping.js
 */
Territory.default_territory = Territory.get("Other", Territory.CONTINENT)
Territory.world = Territory.get("World", Territory.CONTINENT)

/**
 * @type {number} how long it takes a territory to perform toggle animation
 */
Territory.TOGGLE_DURATION = 1000

/**
 * @type {number} Number of states that are currently being shown
 */
Territory._states_shown = 0