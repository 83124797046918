/* global gettext */
/*
 * World Bank population data - https://data.worldbank.org/indicator/SP.POP.TOTL
 * Mortality ICD-10 (part 2/2)
   https://www.who.int/healthinfo/statistics/mortality_rawdata/en/%20

Cannot identify {
    'Mauritius': 52, 'Rodrigues': 1, 'Anguilla': 0, 'British Virgin Islands': 1, 'Bermuda': 3, 'Cayman Islands': 1,
    'Montserrat': 0, 'Saint Pierre and Miquelon': 0, 'Saint Vincent and Grenadines': 5, 'Turks and Caicos Islands': 0,
    'Virgin Islands (USA)': 4, 'Brunei Darussalam': 8, 'Hong Kong SAR': 244, 'Iran (Islamic Republic of)': 1653,
    'Republic of Korea': 1477, 'Syrian Arab Republic': 367, 'Kyrgyzstan': 193, 'TFYR Macedonia': 107,
    'Tajikistan': 187, 'Turkmenistan': 231, 'United Kingdom, England and Wales': 2771,
    'United Kingdom, Northern Ireland': 81, 'United Kingdom, Scotland': 302, 'Solomon Islands': 3
}

WHO data missing for [
    'Algeria', 'Nigeria', 'Senegal', 'Cameroon', 'Togo', 'Ethiopia', 'Sudan', 'Guinea', 'Kenya', 'Ghana', 'Namibia',
    'Gabon', 'Mauritania', 'Rwanda', 'Central African Republic', 'Equatorial Guinea', 'Benin', 'Liberia', 'Somalia',
    'Tanzania', 'Burkina Faso', 'Congo (Kinshasa)', "Cote d'Ivoire", 'Eswatini', 'Congo (Brazzaville)',
    'Republic of the Congo', 'Nepal', 'Cambodia', 'Sri Lanka', 'United Arab Emirates', 'India', 'Afghanistan',
    'Pakistan', 'Indonesia', 'Bhutan', 'Bangladesh', 'Brunei', 'China', 'Iran', 'Vietnam', 'Korea, South', 'Taiwan*',
    'Monaco', 'Azerbaijan', 'Liechtenstein', 'Albania', 'North Macedonia', 'Jersey', 'Guernsey', 'Kosovo',
    'Saint Vincent and the Grenadines', 'Greenland',  'Guam', 'Bolivia', 'Holy See', 'Cruise Ship'
]

// Country categorising:
european_countries = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "United Kingdom"
]

*/
export const territories = [
    {
        "continent": "Africa",
        "label": gettext("Africa"),
        "countries": [
            {
                "name": "Egypt",
                "label": gettext("Egypt"),
                "pop": 95688681,
                "death_avg": 2859
            },
            {
                "name": "Algeria",
                "label": gettext("Algeria"),
                "pop": 40606052
            },
            {
                "name": "Nigeria",
                "label": gettext("Nigeria"),
                "pop": 185989640
            },
            {
                "name": "Morocco",
                "label": gettext("Morocco"),
                "pop": 35276786,
                "death_avg": 271
            },
            {
                "name": "Senegal",
                "label": gettext("Senegal"),
                "pop": 15411614
            },
            {
                "name": "Tunisia",
                "label": gettext("Tunisia"),
                "pop": 11403248,
                "death_avg": 120
            },
            {
                "name": "South Africa",
                "label": gettext("South Africa"),
                "pop": 55908865,
                "death_avg": 2668
            },
            {
                "name": "Cameroon",
                "label": gettext("Cameroon"),
                "pop": 23439189
            },
            {
                "name": "Togo",
                "label": gettext("Togo"),
                "pop": 7606374
            },
            {
                "name": "Ethiopia",
                "label": gettext("Ethiopia"),
                "pop": 102403196
            },
            {
                "name": "Sudan",
                "label": gettext("Sudan"),
                "pop": 39578828
            },
            {
                "name": "Guinea",
                "label": gettext("Guinea"),
                "pop": 12395924
            },
            {
                "name": "Kenya",
                "label": gettext("Kenya"),
                "pop": 48461567
            },
            {
                "name": "Ghana",
                "label": gettext("Ghana"),
                "pop": 28206728
            },
            {
                "name": "Namibia",
                "label": gettext("Namibia"),
                "pop": 2479713
            },
            {
                "name": "Seychelles",
                "label": gettext("Seychelles"),
                "pop": 94677,
                "death_avg": 6
            },
            {
                "name": "Gabon",
                "label": gettext("Gabon"),
                "pop": 1979786
            },
            {
                "name": "Mauritania",
                "label": gettext("Mauritania"),
                "pop": 4301018
            },
            {
                "name": "Rwanda",
                "label": gettext("Rwanda"),
                "pop": 11917508
            },
            {
                "name": "Central African Republic",
                "label": gettext("Central African Republic"),
                "pop": 4594621
            },
            {
                "name": "Equatorial Guinea",
                "label": gettext("Equatorial Guinea"),
                "pop": 1221490
            },
            {
                "name": "Benin",
                "label": gettext("Benin"),
                "pop": 10872298
            },
            {
                "name": "Liberia",
                "label": gettext("Liberia"),
                "pop": 4613823
            },
            {
                "name": "Somalia",
                "label": gettext("Somalia"),
                "pop": 14317996
            },
            {
                "name": "Tanzania",
                "label": gettext("Tanzania"),
                "pop": 55572201
            },
            {
                "name": "Burkina Faso",
                "label": gettext("Burkina Faso"),
                "pop": 18646433
            },
            {
                "name": "Congo (Kinshasa)",
                "label": gettext("Congo (Kinshasa)"),
                "pop": ""
            },
            {
                "name": "Cote d'Ivoire",
                "label": gettext("Cote d'Ivoire"),
                "pop": 23695919
            },
            {
                "name": "Reunion",
                "label": gettext("Reunion"),
                "pop": "",
                "death_avg": 24
            },
            {
                "name": "Eswatini",
                "label": gettext("Eswatini"),
                "pop": ""
            },
            {
                "name": "Congo (Brazzaville)",
                "label": gettext("Congo (Brazzaville)"),
                "pop": ""
            },
            {
                "name": "Mayotte",
                "label": gettext("Mayotte"),
                "pop": "",
                "death_avg": 3
            },
            {
                "name": "Republic of the Congo",
                "label": gettext("Republic of the Congo"),
                "pop": ""
            },
            {
                "name": "Angola",
                "label": gettext("Angola"),
                "pop": ""
            },
            {
                "name": "Botswana",
                "label": gettext("Botswana"),
                "pop": ""
            },
            {
                "name": "Burundi",
                "label": gettext("Burundi"),
                "pop": ""
            },
            {
                "name": "Cabo Verde",
                "label": gettext("Cabo Verde"),
                "pop": "",
                "death_avg": 14
            },
            {
                "name": "Chad",
                "label": gettext("Chad"),
                "pop": ""
            },
            {
                "name": "Djibouti",
                "label": gettext("Djibouti"),
                "pop": ""
            },
            {
                "name": "Eritrea",
                "label": gettext("Eritrea"),
                "pop": ""
            },
            {
                "name": "Gambia",
                "label": gettext("Gambia"),
                "pop": ""
            },
            {
                "name": "Guinea-Bissau",
                "label": gettext("Guinea-Bissau"),
                "pop": ""
            },
            {
                "name": "Libya",
                "label": gettext("Libya"),
                "pop": ""
            },
            {
                "name": "Madagascar",
                "label": gettext("Madagascar"),
                "pop": ""
            },
            {
                "name": "Malawi",
                "label": gettext("Malawi"),
                "pop": ""
            },
            {
                "name": "Mali",
                "label": gettext("Mali"),
                "pop": ""
            },
            {
                "name": "Mozambique",
                "label": gettext("Mozambique"),
                "pop": ""
            },
            {
                "name": "Niger",
                "label": gettext("Niger"),
                "pop": ""
            },
            {
                "name": "Sierra Leone",
                "label": gettext("Sierra Leone"),
                "pop": ""
            },
            {
                "name": "South Sudan",
                "label": gettext("South Sudan"),
                "pop": ""
            },
            {
                "name": "Syria",
                "label": gettext("Syria"),
                "pop": ""
            },
            {
                "name": "Uganda",
                "label": gettext("Uganda"),
                "pop": ""
            },
            {
                "name": "Western Sahara",
                "label": gettext("Western Sahara"),
                "pop": ""
            },
            {
                "name": "Zambia",
                "label": gettext("Zambia"),
                "pop": ""
            },
            {
                "name": "Zimbabwe",
                "label": gettext("Zimbabwe"),
                "pop": ""
            },
            {
                "name": "Sao Tome and Principe",
                "label": gettext("Sao Tome and Principe"),
                "pop": ""
            },
        ]
    },
    {
        "continent": "Asia",
        "label": gettext("Asia"),
        "countries": [
            {
                "name": "Thailand",
                "label": gettext("Thailand"),
                "pop": 68863514,
                "death_avg": 2381
            },
            {
                "name": "Japan",
                "label": gettext("Japan"),
                "pop": 126994511,
                "death_avg": 6977
            },
            {
                "name": "Singapore",
                "label": gettext("Singapore"),
                "pop": 5607283,
                "death_avg": 101
            },
            {
                "name": "Nepal",
                "label": gettext("Nepal"),
                "pop": 28982771
            },
            {
                "name": "Malaysia",
                "label": gettext("Malaysia"),
                "pop": 31187265,
                "death_avg": 397
            },
            {
                "name": "Cambodia",
                "label": gettext("Cambodia"),
                "pop": 15762370
            },
            {
                "name": "Sri Lanka",
                "label": gettext("Sri Lanka"),
                "pop": 21203000
            },
            {
                "name": "United Arab Emirates",
                "label": gettext("United Arab Emirates"),
                "pop": 9269612
            },
            {
                "name": "Philippines",
                "label": gettext("Philippines"),
                "pop": 103320222,
                "death_avg": 2783
            },
            {
                "name": "India",
                "label": gettext("India"),
                "pop": 1324171354
            },
            {
                "name": "Lebanon",
                "label": gettext("Lebanon"),
                "pop": 6006668,
                "death_avg": 85
            },
            {
                "name": "Iraq",
                "label": gettext("Iraq"),
                "pop": 37202572,
                "death_avg": 649
            },
            {
                "name": "Oman",
                "label": gettext("Oman"),
                "pop": 4424762,
                "death_avg": 43
            },
            {
                "name": "Afghanistan",
                "label": gettext("Afghanistan"),
                "pop": 34656032
            },
            {
                "name": "Bahrain",
                "label": gettext("Bahrain"),
                "pop": 1425171,
                "death_avg": 14
            },
            {
                "name": "Kuwait",
                "label": gettext("Kuwait"),
                "pop": 4052584,
                "death_avg": 33
            },
            {
                "name": "Israel",
                "label": gettext("Israel"),
                "pop": 8547100,
                "death_avg": 232
            },
            {
                "name": "Pakistan",
                "label": gettext("Pakistan"),
                "pop": 193203476
            },
            {
                "name": "Qatar",
                "label": gettext("Qatar"),
                "pop": 2569804,
                "death_avg": 12
            },
            {
                "name": "Indonesia",
                "label": gettext("Indonesia"),
                "pop": 261115456
            },
            {
                "name": "Saudi Arabia",
                "label": gettext("Saudi Arabia"),
                "pop": 32275687,
                "death_avg": 250
            },
            {
                "name": "Jordan",
                "label": gettext("Jordan"),
                "pop": 9455802,
                "death_avg": 95
            },
            {
                "name": "Bhutan",
                "label": gettext("Bhutan"),
                "pop": 797765
            },
            {
                "name": "Maldives",
                "label": gettext("Maldives"),
                "pop": 417492,
                "death_avg": 6
            },
            {
                "name": "Bangladesh",
                "label": gettext("Bangladesh"),
                "pop": 162951560
            },
            {
                "name": "Brunei",
                "label": gettext("Brunei"),
                "pop": 423196
            },
            {
                "name": "Mongolia",
                "label": gettext("Mongolia"),
                "pop": 3027398,
                "death_avg": 89
            },
            {
                "name": "China",
                "label": gettext("China"),
                "pop": 1378665000
            },
            {
                "name": "Iran",
                "label": gettext("Iran"),
                "pop": 80277428
            },
            {
                "name": "Vietnam",
                "label": gettext("Vietnam"),
                "pop": 92701100
            },
            {
                "name": "Turkey",
                "label": gettext("Turkey"),
                "pop": 79512426,
                "death_avg": 1946
            },
            {
                "name": "Kazakhstan",
                "label": gettext("Kazakhstan"),
                "pop": 17797032,
                "death_avg": 886
            },
            {
                "name": "Uzbekistan",
                "label": gettext("Uzbekistan"),
                "pop": 31848200,
                "death_avg": 806
            },
            {
                "name": "Korea, South",
                "label": gettext("Korea, South"),
                "pop": 51245707
            },
            {
                "name": "Taiwan*",
                "label": gettext("Taiwan"),
                "pop": 23780452
            },
            {
                "name": "occupied Palestinian territory",
                "label": gettext("occupied Palestinian territory"),
                "pop": 5114000,
                "death_avg": 39
            },
            {
                "name": "Burma",
                "label": gettext("Burma"),
                "pop": ""
            },
            {
                "name": "Kyrgyzstan",
                "label": gettext("Kyrgyzstan"),
                "pop": ""
            },
            {
                "name": "Laos",
                "label": gettext("Laos"),
                "pop": ""
            },
            {
                "name": "Yemen",
                "label": gettext("Yemen"),
                "pop": ""
            },
            {
                "name": "West Bank and Gaza",
                "label": gettext("West Bank and Gaza"),
                "pop": ""
            },
        ]
    },
    {
        "continent": "Europe",
        "label": gettext("Europe"),
        "countries": [
            {
                "name": "Germany",
                "label": gettext("Germany"),
                "pop": 82667685,
                "death_avg": 4871
            },
            {
                "name": "Finland",
                "label": gettext("Finland"),
                "pop": 5495096,
                "death_avg": 286
            },
            {
                "name": "Italy",
                "label": gettext("Italy"),
                "pop": 60600590,
                "death_avg": 3331
            },
            {
                "name": "Sweden",
                "label": gettext("Sweden"),
                "pop": 9903122,
                "death_avg": 497
            },
            {
                "name": "Spain",
                "label": gettext("Spain"),
                "pop": 46443959,
                "death_avg": 2203
            },
            {
                "name": "Belgium",
                "label": gettext("Belgium"),
                "pop": 11348159,
                "death_avg": 588
            },
            {
                "name": "Croatia",
                "label": gettext("Croatia"),
                "pop": 4170600,
                "death_avg": 284
            },
            {
                "name": "Switzerland",
                "label": gettext("Switzerland"),
                "pop": 8372098,
                "death_avg": 352
            },
            {
                "name": "Austria",
                "label": gettext("Austria"),
                "pop": 8747358,
                "death_avg": 437
            },
            {
                "name": "Georgia",
                "label": gettext("Georgia"),
                "pop": 3719300,
                "death_avg": 267
            },
            {
                "name": "Greece",
                "label": gettext("Greece"),
                "pop": 10746740,
                "death_avg": 647
            },
            {
                "name": "Norway",
                "label": gettext("Norway"),
                "pop": 5232929,
                "death_avg": 225
            },
            {
                "name": "Romania",
                "label": gettext("Romania"),
                "pop": 19705301,
                "death_avg": 1404
            },
            {
                "name": "Estonia",
                "label": gettext("Estonia"),
                "pop": 1316481,
                "death_avg": 85
            },
            {
                "name": "San Marino",
                "label": gettext("San Marino"),
                "pop": 33203,
                "death_avg": 2
            },
            {
                "name": "Belarus",
                "label": gettext("Belarus"),
                "pop": 9507120,
                "death_avg": 947
            },
            {
                "name": "Iceland",
                "label": gettext("Iceland"),
                "pop": 334252,
                "death_avg": 12
            },
            {
                "name": "Lithuania",
                "label": gettext("Lithuania"),
                "pop": 2872298,
                "death_avg": 224
            },
            {
                "name": "Ireland",
                "label": gettext("Ireland"),
                "pop": 4773095,
                "death_avg": 159
            },
            {
                "name": "Luxembourg",
                "label": gettext("Luxembourg"),
                "pop": 582972,
                "death_avg": 20
            },
            {
                "name": "Monaco",
                "label": gettext("Monaco"),
                "pop": 38499
            },
            {
                "name": "Azerbaijan",
                "label": gettext("Azerbaijan"),
                "pop": 9762274
            },
            {
                "name": "Armenia",
                "label": gettext("Armenia"),
                "pop": 2924816,
                "death_avg": 152
            },
            {
                "name": "Portugal",
                "label": gettext("Portugal"),
                "pop": 10324611,
                "death_avg": 589
            },
            {
                "name": "Andorra",
                "label": gettext("Andorra"),
                "pop": 77281,
                "death_avg": 2
            },
            {
                "name": "Latvia",
                "label": gettext("Latvia"),
                "pop": 1960424,
                "death_avg": 158
            },
            {
                "name": "Ukraine",
                "label": gettext("Ukraine"),
                "pop": 45004645,
                "death_avg": 5183
            },
            {
                "name": "Hungary",
                "label": gettext("Hungary"),
                "pop": 9817958,
                "death_avg": 707
            },
            {
                "name": "Liechtenstein",
                "label": gettext("Liechtenstein"),
                "pop": 37666
            },
            {
                "name": "Poland",
                "label": gettext("Poland"),
                "pop": 37948016,
                "death_avg": 2115
            },
            {
                "name": "Bosnia and Herzegovina",
                "label": gettext("Bosnia and Herzegovina"),
                "pop": 3516816,
                "death_avg": 196
            },
            {
                "name": "Slovenia",
                "label": gettext("Slovenia"),
                "pop": 2064845,
                "death_avg": 106
            },
            {
                "name": "Serbia",
                "label": gettext("Serbia"),
                "pop": 7057412,
                "death_avg": 560
            },
            {
                "name": "Slovakia",
                "label": gettext("Slovakia"),
                "pop": 5428704,
                "death_avg": 287
            },
            {
                "name": "Malta",
                "label": gettext("Malta"),
                "pop": 436947,
                "death_avg": 18
            },
            {
                "name": "Bulgaria",
                "label": gettext("Bulgaria"),
                "pop": 7127822,
                "death_avg": 595
            },
            {
                "name": "Albania",
                "label": gettext("Albania"),
                "pop": 2876101
            },
            {
                "name": "Cyprus",
                "label": gettext("Cyprus"),
                "pop": 1170125,
                "death_avg": 30
            },
            {
                "name": "France",
                "label": gettext("France"),
                "pop": 66896109,
                "death_avg": 3050
            },
            {
                "name": "Denmark",
                "label": gettext("Denmark"),
                "pop": 5731118,
                "death_avg": 286
            },
            {
                "name": "Moldova",
                "label": gettext("Moldova"),
                "pop": 3552000,
                "death_avg": 215
            },
            {
                "name": "United Kingdom",
                "label": gettext("United Kingdom"),
                "pop": 65637239,
                "death_avg": 3154
            },
            {
                "name": "Netherlands",
                "label": gettext("Netherlands"),
                "pop": 17018408,
                "death_avg": 780
            },
            {
                "name": "North Macedonia",
                "label": gettext("North Macedonia"),
                "pop": 2081206
            },
            {
                "name": "Czechia",
                "label": gettext("Czechia"),
                "pop": 10561633,
                "death_avg": 594,
            },
            {
                "name": "Russia",
                "label": gettext("Russia"),
                "pop": 144342396,
                "death_avg": 15432
            },
            {
                "name": "Jersey",
                "label": gettext("Jersey"),
                "pop": ""
            },
            {
                "name": "Guernsey",
                "label": gettext("Guernsey"),
                "pop": ""
            },
            {
                "name": "Kosovo",
                "label": gettext("Kosovo"),
                "pop": 1816200
            },
            {
                "name": "Montenegro",
                "label": gettext("Montenegro"),
                "pop": ""
            },
            {
                "name": "Holy See",
                "label": gettext("Holy See"),
                "pop": ""
            },
        ]
    },
    {
        "continent": "North America",
        "label": gettext("North America"),
        "countries": [
            {
                "name": "Canada",
                "label": gettext("Canada"),
                "pop": 36286425,
                "death_avg": 1403
            },
            {
                "name": "US",
                "label": gettext("US"),
                "pop": 323127513
            },
            {
                "name": "Greenland",
                "label": gettext("Greenland"),
                "pop": 56186
            },
            {
                "name": "Mexico",
                "label": gettext("Mexico"),
                "pop": 127540423,
                "death_avg": 3396
            }
        ]
    },
    {
        "continent": "Central America",
        "label": gettext("Central America"),
        "countries": [
            {
                "name": "Dominican Republic",
                "label": gettext("Dominican Republic"),
                "pop": 10648791,
                "death_avg": 185
            },
            {
                "name": "Costa Rica",
                "label": gettext("Costa Rica"),
                "pop": 4857274,
                "death_avg": 111
            },
            {
                "name": "US",
                "label": gettext("US"),
                "pop": 323127513,
                "death_avg": 14399
            },
            {
                "name": "Panama",
                "label": gettext("Panama"),
                "pop": 4034119,
                "death_avg": 98
            },
            {
                "name": "Honduras",
                "label": gettext("Honduras"),
                "pop": 9112867,
                "death_avg": 33
            },
            {
                "name": "Jamaica",
                "label": gettext("Jamaica"),
                "pop": 2881355,
                "death_avg": 90
            },
            {
                "name": "Cuba",
                "label": gettext("Cuba"),
                "pop": 11475982,
                "death_avg": 522
            },
            {
                "name": "Antigua and Barbuda",
                "label": gettext("Antigua and Barbuda"),
                "pop": 100963,
                "death_avg": 3
            },
            {
                "name": "Trinidad and Tobago",
                "label": gettext("Trinidad and Tobago"),
                "pop": 1364962,
                "death_avg": 53
            },
            {
                "name": "Guatemala",
                "label": gettext("Guatemala"),
                "pop": 16582469,
                "death_avg": 416
            },
            {
                "name": "Saint Lucia",
                "label": gettext("Saint Lucia"),
                "pop": "",
                "death_avg": 6
            },
            {
                "name": "Saint Vincent and the Grenadines",
                "label": gettext("Saint Vincent and the Grenadines"),
                "pop": ""
            },
            {
                "name": "Puerto Rico",
                "label": gettext("Puerto Rico"),
                "pop": 3411307,
                "death_avg": 162
            },
            {
                "name": "The Bahamas",
                "label": gettext("The Bahamas"),
                "pop": 391232,
                "death_avg": 11
            },
            {
                "name": "Belize",
                "label": gettext("Belize"),
                "pop": "",
                "death_avg": 9
            },
            {
                "name": "Barbados",
                "label": gettext("Barbados"),
                "pop": "",
                "death_avg": 13
            },
            {
                "name": "Dominica",
                "label": gettext("Dominica"),
                "pop": "",
                "death_avg": 3
            },
            {
                "name": "El Salvador",
                "label": gettext("El Salvador"),
                "pop": "",
                "death_avg": 192
            },
            {
                "name": "Grenada",
                "label": gettext("Grenada"),
                "pop": "",
                "death_avg": 5
            },
            {
                "name": "Haiti",
                "label": gettext("Haiti"),
                "pop": ""
            },
            {
                "name": "Nicaragua",
                "label": gettext("Nicaragua"),
                "pop": "",
                "death_avg": 119
            },
            {
                "name": "Saint Kitts and Nevis",
                "label": gettext("Saint Kitts and Nevis"),
                "pop": "",
                "death_avg": 2
            }
        ]
    },
    {
        "continent": "South America",
        "label": gettext("South America"),
        "countries": [
            {
                "name": "Brazil",
                "label": gettext("Brazil"),
                "pop": 207652865,
                "death_avg": 6721
            },
            {
                "name": "Ecuador",
                "label": gettext("Ecuador"),
                "pop": 16385068,
                "death_avg": 353
            },
            {
                "name": "Argentina",
                "label": gettext("Argentina"),
                "pop": 43847430,
                "death_avg": 1793
            },
            {
                "name": "Chile",
                "label": gettext("Chile"),
                "pop": 17909754,
                "death_avg": 553
            },
            {
                "name": "Colombia",
                "label": gettext("Colombia"),
                "pop": 48653419,
                "death_avg": 1150
            },
            {
                "name": "Peru",
                "label": gettext("Peru"),
                "pop": 31773839,
                "death_avg": 551
            },
            {
                "name": "Paraguay",
                "label": gettext("Paraguay"),
                "pop": 6725308,
                "death_avg": 151
            },
            {
                "name": "Bolivia",
                "label": gettext("Bolivia"),
                "pop": 10887882
            },
            {
                "name": "Guyana",
                "label": gettext("Guyana"),
                "pop": 773303,
                "death_avg": 30
            },
            {
                "name": "Uruguay",
                "label": gettext("Uruguay"),
                "pop": 3444006,
                "death_avg": 181
            },
            {
                "name": "Venezuela",
                "label": gettext("Venezuela"),
                "pop": 31568179,
                "death_avg": 813
            },
            {
                "name": "Suriname",
                "label": gettext("Suriname"),
                "pop": 558368,
                "death_avg": 17
            },
            {
                "name": "Martinique",
                "label": gettext("Martinique"),
                "pop": "",
                "death_avg": 17
            },
            {
                "name": "Guadeloupe",
                "label": gettext("Guadeloupe"),
                "pop": "",
                "death_avg": 17
            },
            {
                "name": "Aruba",
                "label": gettext("Aruba"),
                "pop": 104822,
                "death_avg": 4
            },
            {
                "name": "French Guiana",
                "label": gettext("French Guiana"),
                "pop": "",
                "death_avg": 4
            }
        ]
    },
    {
        "continent": "Oceania",
        "label": gettext("Oceania"),
        "countries": [
            {
                "name": "Australia",
                "label": gettext("Australia"),
                "pop": 24127159,
                "death_avg": 834
            },
            {
                "name": "New Zealand",
                "label": gettext("New Zealand"),
                "pop": 4692700,
                "death_avg": 166
            },
            {
                "name": "Guam",
                "label": gettext("Guam"),
                "pop": 162896
            },
            {
                "name": "Fiji",
                "label": gettext("Fiji"),
                "pop": "",
                "death_avg": 37
            },
            {
                "name": "Papua New Guinea",
                "label": gettext("Papua New Guinea"),
                "pop": ""
            },
            {
                "name": "Timor-Leste",
                "label": gettext("Timor-Leste"),
                "pop": ""
            },
        ]
    },
    {
        "continent": "Other",
        "label": gettext("Other"),
        "countries": [
            {
                "name": "Cruise Ship",
                "label": gettext("Cruise Ship"),
                "pop": ""
            }
        ]
    },
    {
        "continent": "World",
        "label": gettext("World"),
        "countries": []
    }
]
