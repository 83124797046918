/* global $, gettext, Editor, Chart, ChartDataLabels */
import {Equation} from './equation'
import {Territory} from './territory'
import {adjust, hashCode, intToRGB, palette, range} from './helper_functions'

const DATASET_BORDER = {
    true: 6,
    false: 3
}


export class Figure {
    constructor(
        type = Figure.TYPE_LOG_TIME, mouse_drag = null,
        tooltip_sorting = null, color_style = null, data_labels = null
    ) {
        this.type = type
        this.last_type = null


        this.mouse_drag = mouse_drag || 0
        this.tooltip_sorting = tooltip_sorting || 0
        this.color_style = color_style || 0
        this.data_labels = data_labels || 0


        this.chart = null

        Figure.figures.push(this)
        this.id = Figure.figures.length
        this.equations = []

        this.$element = $("<canvas />", {id: "figure-" + this.id}).appendTo($("#canvas-container")).data("figure", this)

        Figure.check_canvas_container()
        $("#canvas-container").sorting("> canvas")

        this.hovered_dataset
        this.datasets_used
        Figure.chart_size()

        this.is_line  // if any "line" present = "line"
    }

    static serialize() {
        return Object.values(Figure.figures).map(p => {
            return [p.type,
                p.mouse_drag,
                p.tooltip_sorting,
                p.color_style,
                p.data_labels
            ]
        })
    }

    static deserialize(data) {
        data.forEach((d) => new Figure(...d))
    }

    focus() {
        if (Figure.figures.length > 1) { // highlight figure
            this.$element.css({opacity: 0.3}).animate({opacity: 1}, 1000)
        }
        Figure.current = this

        // migrate all attributes to DOM input values
        const f = (key, attribute = null) => {
            const ion = $("#" + key).data("ionRangeSlider")
            ion.update({from: this[attribute || key.replace(/-/g, "_")]})
            ion.options.onChange(false) // on load time, make this write aloud the legend (ex: "log/time")
        }
        f("axes-type", "type")
        f("mouse-drag")
        f("tooltip-sorting")
        f("color-style")
        f("data-labels")
        return this
    }

    dom_setup() {
        // migrate all DOM input values (stored in `Editor.setup`) concerning to the object to the attributes
        const f = (key, attribute = null) => {
            // Editor.setup["mouse-drag"] => this.mouse_drag
            this[attribute || key.replace(/-/g, "_")] = Editor.setup[key]
            delete Editor.setup[key]
        }
        f("axes-type", "type")
        f("mouse-drag")
        f("tooltip-sorting")
        f("color-style")
        f("data-labels")

        // cascade effect -> show/hide adjacent DOM inputs
        // If we are in Figure.TYPE_LOG_DATASET we can change from line to bar etc.
        // Note that if we are in Figure.TYPE_PERCENT_TIME, line is disabled by the DOM.
        $("#equation-type").closest(".range-container").toggle(!(this.type === Figure.TYPE_LOG_DATASET))

        // XX this is not so elegant; we want to refresh all equations (icon might have changed)
        // but Equation.current has already been refreshed
        this.equations.forEach(p => p.refresh_html())
    }

    static check_canvas_container() {
        $("#canvas-container").toggleClass("multiple", Object.keys(Figure.figures).length > 1)
    }

    add_equation(equation) {
        this.equations.push(equation)
        return this
    }

    remove_equation(equation) {
        this.equations = this.equations.filter(it => it !== equation)
        return this
    }

    /**
     * Assure the figure exists and returns it.
     * @param {int} id
     * @returns {Figure}
     */
    static get(id) {
        id -= 1
        let f
        if (id in Figure.figures) {
            f = Figure.figures[id]
        } else {
            f = new Figure()
        }
        return f
    }

    /*
     * Change size of all the figures.
     * @param {Object} e.from => value
     */
    static chart_size(e) {
        if (e) {
            Figure.default_size = e.from
        }
        if (Editor.iframe || !Figure.figures.length) {
            // when in iframe, special styles apply
            // when loading chart_size is deserialized, however, Figures are still not initialized
            return
        }

        const $cc = $("#canvas-container")
        const max = parseInt($cc.closest(".container").css("max-width"))
        const size = Figure.default_size * max / 100
        $cc.width(size)
        if (size === 0) {
            // setting zero size would break FF
            // we may come here at INIT phase when no window.hash is deserialized
            return
        }

        // If the figures hardly fit into the given space, set their position to absolute.
        const stretch = $("canvas", $cc).width() * Figure.figures.length > max
        $cc.toggleClass("stretch", stretch)
        $("#canvas-wrapper").toggle(stretch).height($cc.height())

        // resize the canvas contents
        Object.values(Figure.figures).forEach(f => f.chart && f.chart.resize())
    }

    /**
     * Resets zoom for all figures.
     * @returns {undefined}
     */
    static reset_zoom() {
        for (const o of Object.values(Figure.figures)) {
            const chart = o.chart
            chart.resetZoom()
        }
        $("#reset-zoom").fadeOut(500)
    }

    /**
     * Remove Figures with no Equation but only if trailing.
     */
    static trailing_check() {
        let changed = false
        ;[...Figure.figures].reverse().some(f => {
            if (f.equations.length) {
                return true // no more trailing Figure, stop looping
            }
            if (f.chart) {
                f.chart.destroy()
                f.chart = null
            }
            f.$element.remove()
            Figure.figures.length -= 1
            changed = true
        })
        if (changed) {
            Figure.check_canvas_container()
            Figure.chart_size()
        }
    }

    /**
     * Unhighlight dataset on mouse leave
     * @returns {undefined}
     */
    mouse_leave() {
        if (this.hovered_dataset !== null && this.chart) {
            this.unhighlight(this.hovered_dataset)
            this.hovered_dataset = null
            this.chart.update()
            // ChartJS 2.9.3 bug: when changing bar chart, it gets reflected on the second `update` call
            this.chart.update()
        }
    }

    /**
     * Get dataset meta properties
     * @param {type} dataset_id
     * @returns {undefined}
     */
    meta(dataset_id) {
        return this.datasets_used[this.chart.data.datasets[dataset_id].id]
    }

    /**
     * Resets the width according to `star` parameter of this.meta property
     * @param {type} dataset_id
     * @returns {undefined}
     */
    unhighlight(dataset_id) {
        const m = this.meta(dataset_id)
        Object.assign(this.chart.data.datasets[dataset_id], {
            borderWidth: DATASET_BORDER[m.star],
            borderColor: m.borderColor,
            backgroundColor: m.backgroundColor
        })
    }

    hover(i) {
        // is already hovered
        if (i === this.hovered_dataset) {
            return
        } else if (this.hovered_dataset !== null) {
            // unhover old dataset
            this.unhighlight(this.hovered_dataset)
        }

        // hightlight new dataset
        this.hovered_dataset = i
        const m = this.meta(i)
        Object.assign(this.chart.data.datasets[i], {
            // XX should not is_line be equation-dependent instead of figure dependent here?
            borderWidth: this.is_line ? 10 : 5,
            borderColor: this.is_line ? m.highlightColor : "red",
            backgroundColor: m.highlightColor
        })
        this.chart.update()
        this.chart.update() // ChartJS 2.9.3 bug: when changing bar chart, it gets reflected on the second `update` call
    }

    init_chart(type = "line", percentage = false) {
        const figure = this
        Chart.plugins.unregister(ChartDataLabels) // XX line should be removed since ChartDataLabels 1.0
        this.hovered_dataset = null
        this.chart = new Chart(this.$element, {
            type: type, // changed to bar if there is no line equation
            data: {},
            plugins: [ChartDataLabels],
            options: {
                legend: {
                    onHover: function(_, item) {
                        figure.hover(item.datasetIndex)
                    }
                },
                onClick: function(evt) {
                    // toggle star on any curve -> if the curve is territory, it is starred as well
                    // this function can receive data (all data on index)
                    const e = this.getElementAtEvent(evt)
                    if (e.length) {
                        const i = e[0]._datasetIndex
                        const dst = figure.meta(i)
                        const star = dst.equation.set_star(dst.territory)
                        const label = dst.equation.territory_label(dst.territory, figure)

                        dst.star = star
                        figure.unhighlight(i)
                        this.data.datasets[i].label = label
                        this.update()
                        Editor.save_hash()
                    }
                },
                onHover: function(evt) {
                    // highlight hovered line
                    const e = this.getElementAtEvent(evt)
                    if (e.length) {
                        figure.hover(e[0]._datasetIndex)
                    }
                },
                title: {
                    display: true,
                    text: gettext("Empty")
                },
                tooltips: {
                    mode: 'index', // mode: 'x' when scatter
                    intersect: false,
                    itemSort: null, // set dynamically
                    callbacks: {
                        title: function(el) {
                            if (Editor.setup["outbreak-on"]) {
                                if (Editor.setup["outbreak-mode"]) {
                                    return gettext("Population outbreak day") + " " + el[0].label
                                }
                                return gettext("Confirmed cases outbreak day") + " " + el[0].label
                            } else {
                                return el[0].label
                            }
                        },
                        label: function(el, data) {
                            let label = data.datasets[el.datasetIndex].label || ''
                            const v = isNaN(el.value) ?
                                gettext("no data")
                                : Math.round(el.value * 10 ** figure.decimal) / 10 ** figure.decimal
                            if (el.datasetIndex === figure.hovered_dataset) {
                                label = "→ " + label
                            }

                            if (figure.type === Figure.TYPE_LOG_DATASET) { // Absolute numbers axe X
                                label += ` (${el.label}, ${v})`
                            } else {
                                // Timeline axe X
                                if (label) {
                                    label += ': '
                                }
                                label += v
                                if (Editor.setup["outbreak-on"]) {
                                    const start = figure.meta(el.datasetIndex).outbreak_start
                                    if (start) { // if aggregating, outbreak_start is not known
                                        const day = Territory.header[parseInt(start) + parseInt(el.label)]
                                        label += " (" + (day === undefined ?
                                            gettext("for the given territory, this is a future date") : day.toYMD())
                                            + ")"
                                    }
                                }
                            }
                            return label
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        //                            ticks: {
                        //                                min: 0,
                        //                                max: 10 ** 4
                        //                            },
                        display: true,
                        scaleLabel: {
                            display: true
                        },
                        stacked: true,
                        id: "normal"
                    }
                    ],
                    yAxes: range(1, 6).map(i => { // create 5 available axes
                        return {
                            display: false,
                            id: i,
                            scaleLabel: {
                                display: true,
                                labelString: i === 1 ? gettext("Cases") : gettext("Axe") + i
                            },
                            ticks: {
                                //                                min: 0,
                                //                                max: 10 ** 4,
                                callback: function(value) {
                                    if (figure.type === Figure.TYPE_PERCENT_TIME) {
                                        return value + " %"
                                    }
                                    return Math.round(value * 10 ** figure.decimal) / 10 ** figure.decimal
                                }
                            }
                        }
                    })
                },
                plugins: {
                    zoom: {
                        // Container for pan options
                        pan: {
                            // Boolean to enable panning
                            enabled: false,

                            // Panning directions. Remove the appropriate direction to disable
                            // Eg. 'y' would only allow panning in the y direction
                            // A function that is called as the user is panning and returns the
                            // available directions can also be used:
                            //   mode: function({ chart }) {
                            //     return 'xy';
                            //   },
                            mode: 'xy',

                            rangeMin: {
                                // Format of min pan range depends on scale type
                                x: null,
                                y: null
                            },
                            rangeMax: {
                                // Format of max pan range depends on scale type
                                x: null,
                                y: null
                            },
                            // On category scale, factor of pan velocity
                            speed: 20,
                            // Minimal pan distance required before actually applying pan
                            threshold: 1
                        },
                        // Container for zoom options
                        zoom: {
                            // Boolean to enable zooming
                            enabled: true,
                            // Enable drag-to-zoom behavior
                            drag: false,
                            mode: 'xy',

                            rangeMin: {
                                // Format of min zoom range depends on scale type
                                x: null,
                                y: null
                            },
                            rangeMax: {
                                // Format of max zoom range depends on scale type
                                x: null,
                                y: null
                            },
                            // Speed of zoom via mouse wheel
                            // (percentage of zoom on a wheel event)
                            speed: 0.1,
                            // On category scale, minimal zoom level before actually applying zoom
                            sensitivity: 3,
                            onZoomComplete: function() {
                                $("#reset-zoom").show()
                            }
                        }
                    },
                    datalabels: {
                        display: false,
                        color: 'white',
                        backgroundColor: function({dataset}) {
                            return dataset.backgroundColor
                        },
                        borderRadius: 4,
                        font: {
                            weight: 'bold'
                        },
                        formatter: null
                    },
                    stacked100: {
                        enable: percentage,
                        replaceTooltipLabel: false
                    }
                },
                animation: {
                    onComplete: () => {
                        if (this.id === 1 && Editor.REFRESH_THUMBNAIL === Editor.chart_id && Editor.show_menu) {
                            // chartjs animation finshed, we can alter the image.
                            // But only if Editor.chart_id would not change meanwhile (we did not move sliders)
                            Editor.REFRESH_THUMBNAIL = 0
                            Editor.export_thumbnail()
                        }
                    }
                }
            }
        })
        return this.chart
    }

    get_active_equations() {
        return this.equations.filter(p => p.active)
    }

    // eslint-disable-next-line complexity
    refresh() {
        /**
         * @type {Equation} equation
         */
        let equation
        /**
         * @type {Territory} territory
         */
        let territory
        let data, outbreak_start
        let longest_data = 0
        const datasets = {}
        const datasets_used_last = this.datasets_used
        this.datasets_used = {}

        const [equation_data, boundaries, title] = Equation.get_data(this.get_active_equations())
        const y_axes = new Set()
        let static_color_i = 0

        // Y Axis rounding
        // We round to 3 decimal points by default.
        // But if the biggest number is still smaller than 0.001, we round to more.
        const max = boundaries[1] ? Math.log10(boundaries[1]) : 0
        this.decimal = Math.ceil(max < -3 ? Math.abs(max) : 0) + 3

        for ([equation, territory, data, outbreak_start] of equation_data) {

            // choose only some days in range
            if (!data.length) {
                // hide the country from the figure if it has no data (ex: because of the date range settings)
                if (!equation.valid) {
                    return false
                }
                continue
            }

            // longest dataset when using multiple day
            longest_data = Math.max(longest_data, Editor.setup["day-range"][0] + data.length)

            // prepare dataset options
            const [starred, id] = equation.territory_info(territory)
            const c = territory.color || intToRGB(hashCode(territory.get_name()))
            const color = {
                [Figure.COLOR_STYLE_TERRITORY_EQUATION]: adjust(c, equation.hash),
                [Figure.COLOR_STYLE_TERRITORY]: c,
                [Figure.COLOR_STYLE_EQUATION]: equation.color(),
                [Figure.COLOR_STYLE_STATIC]: palette[static_color_i++ % palette.length]
            }[Figure.COLOR_STYLE[this.color_style]]
            const border_color = (
                equation.type === Equation.TYPE_STACKED_TERRITORY
                && Figure.COLOR_STYLE[this.color_style] === "territory + expression"
            ) ? 'rgba(0,0,0,1)' : color // colours of the same territory are hardly distinguishable

            // push new dataset
            datasets[id] = {
                type: equation.type ? 'bar' : 'line',
                label: equation.territory_label(territory, this),
                data: data,
                fill: false,
                id: id,
                xAxisID: "normal",
                stack: equation.type > Equation.TYPE_BAR ?
                    (equation.type === Equation.TYPE_STACKED_TERRITORY ? territory.dom_id : "p" + equation.id) : id,
                yAxisID: parseInt(equation.y_axis),
                borderWidth: DATASET_BORDER[starred],
                borderColor: border_color,
                backgroundColor: color
            }

            this.datasets_used[id] = {// available through this.meta(i)
                equation: equation,
                territory: territory,
                star: false,
                outbreak_start: outbreak_start,
                type: equation.type,
                backgroundColor: color,
                borderColor: border_color,
                highlightColor: adjust(color, +40)
            }
            y_axes.add(parseInt(equation.y_axis))
            //            console.log("Dataset color", id, label, color, adjust(color, 40), adjust(color, -40));
            //            console.log("Dataset", label, chosen_data.length, dataset.stack);
            // console.log("Push name", equation.get_name(), equation.id, territory);
        }

        // Transform data according to Percent axes
        if (this.type === Figure.TYPE_PERCENT_TIME) {
            // lengthen all the datasets to the same, max length, fill with NaN
            // stacked100.js plugin fails with dataset of different length
            const max = Math.max(...Object.values(datasets).map(d => d.data.length))
            Object.values(datasets).forEach(d => d.data.push(...Array(max - d.data.length).fill(NaN)))
        }

        // Axe X Labels
        const r = Editor.setup["single-day"] ?
            [Editor.setup["day-range"][0]]
            : range(Editor.setup["day-range"][0], Math.min(longest_data, Editor.setup["day-range"][1]) + 1)
        const labels = this.type === Figure.TYPE_LOG_DATASET ?
            null : (Editor.setup["outbreak-on"] ? r.map(String) : r.map(day => Territory.header[parseInt(day)].toDM()))

        // destroy current chart if needed
        // ChartJS cannot dynamically change line type (dataset left align) to bar (centered).
        // We have bar if single day (centered) and if there is no line equation.
        this.is_line = !Editor.setup["single-day"] && Object.values(datasets).some(d => d.type === "line")
        const percentage = this.type === Figure.TYPE_PERCENT_TIME // Stacked percentage if at least one equation has it
        if (this.chart && (// cannot change dynamically line to bar
            (this.chart.config.type === "line") !== this.is_line ||
            // cannot turn on/off stacked100 plugin dynamically
            this.chart.config.options.plugins.stacked100.enable !== percentage
        )) {
            this.chart = this.chart.destroy()
        }

        // update chart data
        if (!this.chart) {
            this.chart = this.init_chart(this.is_line ? "line" : "bar", percentage)
            this.chart.data = {datasets: Object.values(datasets), labels: labels}
        } else {
            // update just some datasets, do not replace them entirely (smooth movement)
            this.chart.data.labels = labels
            const removable = []
            // update changed
            for (const i in this.chart.data.datasets) { // for each current dataset
                const o = this.chart.data.datasets[i]
                if (o.id in datasets) { // check if there if current dataset still present
                    const d = datasets[o.id]
                    const last = datasets_used_last[o.id]
                    if (last && last.type > Equation.TYPE_LINE
                        && last.type !== this.datasets_used[o.id].equation.type) {
                        // if equation.type changed from the last type to a non-line, hard update
                        // (probably) due to a bug in ChartJS, if (ex.) bar changes to stacked smoothly,
                        // the change is not visible, it remains non-stacked,
                        // do a hard update of the dataset (not smooth movement)
                        this.chart.data.datasets[i] = d
                    } else {
                        // even though we change all current dataset properties but without swapping the object itself,
                        // smooth change will not re-render equation-create animation
                        for (const [key, prop] of Object.entries(d)) {
                            o[key] = prop
                        }
                    }
                    delete datasets[o.id]
                } else { // current dataset is no more listed
                    removable.push(o)
                }
            }
            // remove unused
            this.chart.data.datasets = this.chart.data.datasets.filter((el) => !removable.includes(el))
            // insert new
            Object.values(datasets).forEach(el => this.chart.data.datasets.push(el))
        }


        const opt = this.chart.options

        // Set figure title
        if (!equation_data.length) { // error when processing equation function formula
            opt.title.text = gettext("Empty figure") + " " + this.id
        } else if (!this.chart.data.datasets.length) {
            opt.title.text = gettext("No data")
        } else {
            opt.title.text = title.join(", ")
        }

        // Axis X label and type
        opt.scales.xAxes[0].scaleLabel.labelString = this.axe_x_title()
        opt.scales.xAxes[0].type = this.type === Figure.TYPE_LOG_DATASET ? "logarithmic" : "category"
        // LOG_DATASET would implicitly start at higher number which looks bad; start at the lowest possible value
        // opt.scales.xAxes[0].ticks.min = this.type === Figure.TYPE_LOG_DATASET ? boundaries[3] : null;
        // XX chartjs cannot plot logarithmic negative scale
        // opt.scales.yAxes[0].ticks.min = this.type === Figure.TYPE_LOG_TIME ? boundaries[0] : null
        opt.tooltips.mode = this.is_line ? (this.type === Figure.TYPE_LOG_DATASET ? "x" : "index") : "x"


        // Since Figure.TYPE_LOG_DATASET have no notion of time, make last few points bigger,
        // else 3 is the default point size
        opt.elements.point.radius = this.type === Figure.TYPE_LOG_DATASET ? (context => {
            const i = 10 // makes bold certain number of last points
            return 2 + Math.max(context.dataIndex - context.dataset.data.length + i, 0) / i * 5
        }) : 3


        // Axis Y
        opt.scales.yAxes.forEach(axe => {
            switch (this.type) {
                case Figure.TYPE_LOG_DATASET:
                case Figure.TYPE_LOG_TIME:
                    axe.type = "logarithmic"
                    break
                case Figure.TYPE_LINEAR_TIME:
                case Figure.TYPE_PERCENT_TIME:
                default:
                    axe.type = "linear"
                    break
            }
            axe.display = y_axes.has(axe.id)
        })

        // Apply other figure settings

        // Set zoom plugin
        const z = this.chart.config.options.plugins.zoom
        // if semicolon is missing after z initialization as eslint wants
        // destructed array is confused with an array index brackets []
        ;[z.zoom.enabled,
            z.zoom.drag,
            z.pan.enabled] = {
            [Figure.MOUSE_DRAG_OFF]: [false, false, false],
            [Figure.MOUSE_DRAG_ZOOM]: [true, true, false],
            [Figure.MOUSE_DRAG_PAN]: [false, false, true],
            [Figure.MOUSE_DRAG_PAN_WHEEL]: [true, false, true]
        }[Figure.MOUSE_DRAG[this.mouse_drag]]
        // this.$element.toggleClass("grabbable", z.zoom.enabled || z.pan.enabled);

        // Tooltip sorting
        opt.tooltips.itemSort = {
            [Figure.TOOLTIP_SORTING_VAL]: (a, b) => b.value - a.value,
            [Figure.TOOLTIP_SORTING_EXPRESSION]: (a, b, data) => { // sort by equation name, then by dataset name
                const [i, j] = [
                    this.meta(b.datasetIndex).equation.expression,
                    this.meta(a.datasetIndex).equation.expression
                ]
                if (i === j) {
                    return data.datasets[b.datasetIndex].label > data.datasets[a.datasetIndex].label ? -1 : 1
                }
                return i > j ? -1 : 1
            },
            [Figure.TOOLTIP_SORTING_TERRITORY]: (a, b, data) => // sort by dataset name
                data.datasets[b.datasetIndex].label > data.datasets[a.datasetIndex].label ? -1 : 1
        }[Figure.TOOLTIP_SORTING[this.tooltip_sorting]]


        // Data labels are off by default when TYPE_LOG_DATASET used
        const v = Figure.DATA_LABELS[this.data_labels]
        opt.plugins.datalabels.display =
            v !== Figure.DATA_LABELS_OFF && !(v === Figure.DATA_LABELS_DEFAULT && this.type === Figure.TYPE_LOG_DATASET)

        const few_items = Editor.setup["single-day"] ?
            true : Editor.setup["day-range"][1] - Editor.setup["day-range"][0] < 5
        opt.plugins.datalabels.formatter = (value, item) => {
            return ({
                [Figure.DATA_LABELS_DEFAULT]: this.is_line ? null :
                    // by default shown on figure with bars only (no line chart)
                    // and only if there are few days only displayed (otherwise this would be a mess)
                    (few_items ? item.dataset.label : null),
                [Figure.DATA_LABELS_LABEL]: item.dataset.label,
                [Figure.DATA_LABELS_VALUES]: this.type === Figure.TYPE_LOG_DATASET ? value.x + " " + value.y : value,
                [Figure.DATA_LABELS_BOTH]: (item.dataset.label + "\n"
                    + (this.type === Figure.TYPE_LOG_DATASET ? value.x + " " + value.y : value)),
                [Figure.DATA_LABELS_OFF]: null
            }[Figure.DATA_LABELS[this.data_labels]])
        }

        // Submit changes
        this.chart.update()
        this.prepare_export()
        return boundaries
    }

    prepare_export() {
        const ch = this.chart
        const rows = []

        // insert header
        if (!this.type === Figure.TYPE_LOG_DATASET) {
            rows.push(['"' + ch.options.scales.xAxes[0].scaleLabel.labelString + '"', ...ch.data.labels])
        }
        // insert values

        ch.data.datasets.forEach(d => {
            // XX when using Figure.TYPE_LOG_DATASET, {x: ..., y: ...} is printed in the cell.
            // This is not nice, it should be in header. However they can export in JSON.
            rows.push([d.label, ...d.data.map(JSON.stringify)])
        })
        this.$element.data("prepared_export", [ch.options.title.text + ".csv", rows.join("\n")])

        // html table
        const table = []
        rows.forEach(r => {
            table.push("<tr><td>", r.join("</td><td>"), "</td></tr>")
        })
        $("#export-data").append(table.join(""))
    }

    export_json() {
        const ch = this.chart
        const result = {"labels": ch.data.labels}
        ch.data.datasets.forEach(d => {
            result[d.label] = d.data
        })
        return JSON.stringify(result)
    }

    axe_x_title() {
        let axe_title = Editor.setup["single-day"] ?
            gettext("Day") + ": " + (
                Editor.setup["outbreak-on"] ? Editor.setup["day-range"][0]
                    : Territory.header[Editor.setup["day-range"][0]].toDM()
            ) + " " : ""
        const text_population = gettext("population")
        if (this.type === Figure.TYPE_LOG_DATASET) {
            axe_title += {
                "C": "Confirmed",
                "R": "Recovered",
                "D": "Dead",
                "T": "Tested"
            }[Editor.setup["x-axis"]] + " " + gettext("cases")
            if (Editor.setup["outbreak-on"]) {
                const text_since = gettext("since")
                axe_title += Editor.setup["outbreak-mode"] ?
                    ` ${text_since} >= (${Editor.setup["outbreak-value"]} * ${text_population}/100 000)`
                    : ` ${text_since} >= ${Editor.setup["outbreak-value"]}`
            }

        } else {
            const text_days = gettext("Days count since confirmed cases")
            axe_title += Editor.setup["outbreak-on"] ? (Editor.setup["outbreak-mode"] ?
                `${text_days} >= (${Editor.setup["outbreak-value"]} * ${text_population}/100 000)`
                : `${text_days} >= ${Editor.setup["outbreak-value"]}`) : ""
        }
        return axe_title
    }
}

/**
 *
 * @type {Figure[]}
 */
Figure.figures = []
Figure.default_size = null

Figure.TYPE_LINEAR_TIME = 0
Figure.TYPE_LOG_TIME = 1
Figure.TYPE_PERCENT_TIME = 2
Figure.TYPE_LOG_DATASET = 3
Figure.TYPE = [
    gettext("linear / time"),
    gettext("log / time"),
    gettext("percent / time"),
    gettext("log / dataset")
]

// constants
// Change everywhere before renaming a constant, however you can re-order freely. The first one becomes the default.
Figure.MOUSE_DRAG_OFF = gettext("off")
Figure.MOUSE_DRAG_ZOOM = gettext("zoom")
Figure.MOUSE_DRAG_PAN = gettext("pan")
Figure.MOUSE_DRAG_PAN_WHEEL = gettext("pan + wheel zoom")
Figure.MOUSE_DRAG = [Figure.MOUSE_DRAG_OFF, Figure.MOUSE_DRAG_ZOOM, Figure.MOUSE_DRAG_PAN, Figure.MOUSE_DRAG_PAN_WHEEL]

Figure.TOOLTIP_SORTING_VAL = gettext("by value")
Figure.TOOLTIP_SORTING_EXPRESSION = gettext("by expression")
Figure.TOOLTIP_SORTING_TERRITORY = gettext("by territory")
Figure.TOOLTIP_SORTING = [Figure.TOOLTIP_SORTING_VAL,
    Figure.TOOLTIP_SORTING_EXPRESSION,
    Figure.TOOLTIP_SORTING_TERRITORY]

Figure.COLOR_STYLE_TERRITORY_EQUATION = gettext("territory + equation")
Figure.COLOR_STYLE_TERRITORY = gettext("territory")
Figure.COLOR_STYLE_EQUATION = gettext("equation")
Figure.COLOR_STYLE_STATIC = gettext("static")
Figure.COLOR_STYLE = [
    Figure.COLOR_STYLE_TERRITORY_EQUATION,
    Figure.COLOR_STYLE_TERRITORY,
    Figure.COLOR_STYLE_EQUATION,
    Figure.COLOR_STYLE_STATIC]

Figure.DATA_LABELS_DEFAULT = gettext("default")
Figure.DATA_LABELS_LABEL = gettext("label")
Figure.DATA_LABELS_VALUES = gettext("values")
Figure.DATA_LABELS_BOTH = gettext("both")
Figure.DATA_LABELS_OFF = gettext("off")
Figure.DATA_LABELS = [
    Figure.DATA_LABELS_DEFAULT,
    Figure.DATA_LABELS_LABEL,
    Figure.DATA_LABELS_VALUES,
    Figure.DATA_LABELS_BOTH,
    Figure.DATA_LABELS_OFF]

Figure.X_AXIS_C = gettext("confirmed")
Figure.X_AXIS_R = gettext("recovered")
Figure.X_AXIS_D = gettext("death")
Figure.X_AXIS_T = gettext("tested")
Figure.X_AXIS = [Figure.X_AXIS_C, Figure.X_AXIS_R, Figure.X_AXIS_D, Figure.X_AXIS_T]

/**
 *
 * @type Figure
 */
Figure.current = null
