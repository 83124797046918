/**
 * Input field DOM
 *  * `dom_setup` method will register it to the `Editor.setup` variable
 *  * rewritten in (Equation|Figure).focus()
 *
 *
 * Editor -> Figure -> Equation -> dataset -> Territory
 *
 */
/* global $ */
import {Figure} from './figure'
import {Equation} from './equation'
import {Territory} from './territory'
import {downloadFile, exportCanvasAsPNG, hashFnv32a, Interval, logslider, range} from './helper_functions'

window.Figure = Figure
window.Equation = Equation
window.Territory = Territory

// definitions
let ready_to_refresh = false
let just_stored_hash = "" // determine if hash change is in progress

const edvard_deployment = window.location.hostname.indexOf("edvard") > -1

let chart_src
const node = document.getElementById("chart")
if (node && node.dataset.chart_src) {
    chart_src = node.dataset.chart_src
} else {
    chart_src = "https://onemocneni-aktualne.mzcr.cz/api/v2/covid-19/testy-kraje.csv"
}

// XX may be moved to article_with_chart.html so that sources are loaded asynchronously
// eslint-disable-next-line max-len
const url_pattern = 'https://raw.githubusercontent.com/CSSEGISandData/COVID-19/master/csse_covid_19_data/csse_covid_19_time_series/time_series_covid19_'
const d = d => d.text()
let tests_czech // tests_czech might be removed in the next version when we compact all sources to single file
const source_data = [
    fetch(url_pattern + "confirmed_global.csv").then(d).then(data => Territory.build(data, "confirmed")),
    fetch(url_pattern + "deaths_global.csv").then(d).then(data => Territory.build(data, "deaths")),
    fetch(url_pattern + "recovered_global.csv").then(d).then(data => Territory.build(data, "recovered")),
    fetch(chart_src).then(d).then(data => {tests_czech = data})
]

const Editor = window.Editor = {
    setup: {},
    $equation: null,
    REFRESH_THUMBNAIL: typeof window.REFRESH_THUMBNAIL !== 'undefined' ? window.REFRESH_THUMBNAIL : '',
    // XX CZ.NIC has chart_id as URL parameter if needed '?chart=CHART_ID'
    chart_id: typeof window.chart_id !== 'undefined' ? window.chart_id : '',
    show_menu: true,
    iframe: false,
    save_hash: function() {
        // save to hash
        Editor.setup["equations"] = Equation.serialize()
        Editor.setup["figures"] = Figure.serialize()

        // ignore day-range from unique hash -> day-range can vary but thumbnail will stay the same
        // Default day-range (all days) is longer every day and the hash for the same chart would vary.
        const day_range = Editor.setup["day-range"]
        delete Editor.setup["day-range"]
        Editor.chart_id = hashFnv32a(JSON.stringify(Editor.setup), true)
        Editor.setup["day-range"] = day_range


        history.pushState(null, "", Editor.serialize(Editor.setup, true))
        // console.log("Hash stored with val: ", Editor.setup["outbreak-value"]);
    },


    show_menu_now: function() {
        // start plotting
        if (!Equation.current) {
            // console.debug("Equation.current -> creating new", Editor.setup["equation-expression"], Editor.setup)
            // initialize a equation and give it initial math expression from DOM
            (new Equation(Editor.setup["equation-expression"])).focus()
            for (const country of ["Czechia", "Italy"]) { // Xeuropean_countries
                Territory.get_by_name(country, Territory.COUNTRY).set_active().show()
            }
        }
        Figure.chart_size({"from": Editor.setup["chart-size"]})
        Editor.refresh(true)
        Territory.states_shown() // if no Territory.STATE encountered, States column will be hidden
        $("main").fadeIn(2000)
    },

    init_editor: function() {
        Editor.$equation = $("#equation-expression")

        // Try load Editor.chart_id from the page content.
        if (!Editor.chart_id) {
            const node = document.getElementById("chart")
            if (node && node.dataset.chart) {
                Editor.chart_id = hashFnv32a(node.dataset.chart, true)
            }
        }
        // Show show cases or editor
        if (!Editor.chart_id && edvard_deployment) { // showcases shown only if not deployed at nic.cz
            // console.log("SHOW MENU NOW", Editor.chart_id)
            Editor.show_menu = false
            $("#showcases").fadeIn(500).on("click", "a", function() {
                $("#showcases").hide()
                history.pushState(null, "", $(this).attr("href"))
                Editor.load_hash()
                Editor.show_menu_now()
                Editor.refresh(true)
                return false
            })
        }


        // DOM configuration

        // changing language keeps current editor setup
        $("body > nav > ul > li.lang > a").on("click", function() {
            // strip path from the location
            location.href = $(this).attr("href")
                + location.href.slice(location.origin.length + location.pathname.length)
            return false
        })

        // canvas configuration
        $("#canvas-container").on("mouseleave", "canvas", function() {
            $(this).data("figure").mouse_leave() // unhighlight dataset on mouse leave
        })

        // init sliders
        $("#chart-size").ionRangeSlider({
            skin: "big",
            grid: false,
            min: $("#chart-size").attr("min"),
            max: $("#chart-size").attr("max"),
            from: $("#chart-size").val(),
            postfix: " %",
            onChange: Figure.chart_size
        })

        // display menu - define from:0 and min:0 as the initial values
        // if `Editor.setup` is loadable and element is missing from `Editor.setup`
        $("#mouse-drag").ionRangeSlider({
            skin: "big", grid: false, from: 0, min: 0,
            values: Figure.MOUSE_DRAG
        })
        $("#tooltip-sorting").ionRangeSlider({
            skin: "big", grid: false, from: 0, min: 0,
            values: Figure.TOOLTIP_SORTING
        })
        $("#color-style").ionRangeSlider({
            skin: "big", grid: false, from: 0, min: 0,
            values: Figure.COLOR_STYLE
        })
        $("#data-labels").ionRangeSlider({
            skin: "big", grid: false, from: 0, min: 0,
            values: Figure.DATA_LABELS
        })

        // axes menu
        $("#equation-type").ionRangeSlider({
            skin: "big", grid: false, from: 0, min: 0,
            values: Equation.TYPE,
            onFinish: function() {
                const ion = $("#equation-type").data("ionRangeSlider")
                if (Figure.current.type === Figure.TYPE_PERCENT_TIME && ion.result.from === Equation.TYPE_LINE) {
                    ion.update({from: Equation.TYPE_STACKED_EQUATION})
                }

            }
        })
        $("#axes-type").ionRangeSlider({
            skin: "big", grid: false, from: 0, min: 0,
            values: Figure.TYPE
        })
        $("#x-axis").ionRangeSlider({
            skin: "big", grid: false, from: 0, min: 0,
            values: Figure.X_AXIS
        })

        $("#day-range").ionRangeSlider({
            skin: "big",
            type: "double",
            grid: true,
            min: 0,
            max: 1,
            to: 1,
            from: 0
        })


        $("#outbreak-threshold").ionRangeSlider({
            skin: "big",
            grid: true,
            from: 1,
            values: [1]
        })

        // single day switch
        $("#single-day").change(function() {
            // it is more intuitive "to" value becomes the single day
            const ion = $("#day-range").data("ionRangeSlider")
            const single = $(this).prop("checked")
            const was_single = ion.options.type === "single"
            if (single !== was_single) {
                const o = {type: single ? "single" : "double"}
                const c = ion.result
                if (single) { // changing to single day
                    o._from = c.from
                    o.from = c.to
                } else { // changing to multiple day
                    o.from = ion.options._from
                    o.to = c.from
                    if (o.to <= o.from) { // we have moved "to" value before previously stored "from" value, reset it
                        o.from = 0
                    }
                }
                ion.update(o)
            }

        }).change()

        // play menu
        Editor.setup["play-boundaries"] = [] // min, max
        $("#play-container").on("click", ".btn", function(event) {
            const ion = $("#day-range").data("ionRangeSlider")
            const boundaries = Editor.setup["play-boundaries"] || []
            if (!Editor.play) {
                Editor.play = new Interval(() => {
                    const key = ion.options.type === "double" ? "to" : "from"
                    let v = ion.result[key] + 1
                    if (v > boundaries[1]) {
                        v = boundaries[0]
                    }
                    ion.update({[key]: v})
                    Editor.refresh(false)
                }, 50).stop()
            }
            switch (event.target.id) {
                case "play-button":
                    if (!boundaries.length) { // we were not paused, refresh values
                        boundaries.length = 0
                        boundaries.push(...(ion.options.type === "double" ?
                            [ion.result.from + 1, ion.result.to] : [ion.result.from, ion.options.max]))
                    }
                    $("#play-button").hide()
                    $("#pause-button, #stop-button").show()
                    Editor.play.start()
                    break
                case "pause-button":
                    Editor.play.stop()
                    $("#play-button").show()
                    $("#pause-button").hide()
                    break
                case "stop-button":
                    Editor.play.stop()
                    boundaries.length = 0
                    // ion.update({[key]: v}); XX restore `to` when double
                    $("#play-button").show()
                    $("#pause-button, #stop-button").hide()
                    Editor.refresh()
                    break
                default:
                    break
            }
        })


        // disabling outbreak will disable its range
        $("#outbreak-on").change(function() {
            $("#outbreak-threshold, #outbreak-value, #outbreak-mode").parent().toggle($(this).prop("checked"))
        }).change()

        // refresh on input change
        // every normal input change will redraw chart
        // (we ignore sliders and .copyinput (#export-link would cause recursion)
        $("#setup input:not(.irs-hidden-input):not(.nohash)").change(Editor.refresh)

        // sliders input change
        $("#setup input.irs-hidden-input").each(function() {
            const ion = $(this).data("ionRangeSlider")

            $(`label[for=${ion.input.id}]`).click(() => {
                // XX clicking on the label does not focus IRS yet
                $(this).siblings("span.irs").find(".irs-handle.from").focus()
                // console.log("HERE", $(this).siblings("span.irs").find(".irs-handle.from"));
                return false
            })
            // make labels focus the ion

            const opt = $(this).data("ionRangeSlider").options
            if ($(this).closest("#view-menu").length) {
                // we are in the view menu DOM context
                // each change in the view menu should be remembered (note that its onFinish event is rewritten)
                opt.onFinish = Editor.refresh
                return
            }

            // we are in the main application DOM context
            //        let $input = $(this).data("bound-$input"); // bound input to the slider
            const $legend = $($(this).attr("data-legend"))
            const $input = $($(this).attr("data-input")) // bound input to the slider
            $(this).data("has-bound-input", $input.length > 0)

            if ($legend.length) {
                ion.update({hide_from_to: true}) // stop current value being shown at the top
            }

            const clb_finish = opt.onFinish
            opt.onFinish = () => {
                clb_finish ? clb_finish() : null // call another callback
                Editor.dom_setup
            }

            // do not change window hash when moving input slider
            const clb = opt.onChange // keep previously defined callback
            let old_val = []
            opt.onChange = (it) => {
                const {from, to} = ion.result

                clb ? clb() : null // call another callback
                if ($input.length || $legend.length) {
                    const val = opt.values.length ? opt.values[from] : from
                    $input.val(val)  // if there is a bound input, change its value accordingly
                    $legend.html(val)
                }

                // ignore if not changed
                // when dragging mouse, just clicking on the slider would cause this to be fired
                if ([from, to, Equation.current].every((value, index) => value === old_val[index])) {
                    return
                }
                old_val = [from, to, Equation.current]
                if (it !== false) {
                    // when focusing Figure, we change many sliders but wish not trigger a hundred of refreshes
                    Editor.refresh(ion.input.id !== "outbreak-threshold")
                }
            }

        })
        // refresh on equation change
        Editor.$equation.keyup(function() {
            const v = $(this).val()
            if ($(this).data("last") !== v) {
                Equation.current.refresh_html(v)
                Editor.refresh()
                $(this).data("last", v)
            }
        })
        // place equation on a different figure
        $("#equation-stack").on("change", ".equation-figure", function() {
            ready_to_refresh = false
            const equation = $(this).parent().data("equation").focus()
            equation.set_figure(Figure.get($(this).val() * 1).focus())
            ready_to_refresh = true
            Editor.refresh() // Xrefresh(false);
        })
        // place equation on a different Y axe
        $("#equation-stack").on("change", ".y-axis", function() {
            /**
             *
             * @type {Equation}
             */
            const equation = $(this).parent().data("equation").focus()
            equation.y_axis = $(this).val()
            if (equation.active) {
                // possible chartjs bug – if I did not toggle the equation activity,
                // Y axis would appear but data would stay still wrongly linked to the old Y axis
                equation.active = false
                Editor.refresh(false)
                equation.active = true
                Editor.refresh(false)
            }
        })
        // possibility to add a new equation
        $("#equation-new").click(() => {
            const cp = Equation.current
            if (!cp.valid) {
                alert("This equation expression is invalid")
                Editor.$equation.focus()
                return
            }
            // Equation.current.assure_stack();
            Editor.$equation.val("")
            const p = new Equation()
            p.checked = Object.assign(cp.checked)
            p.starred = Object.assign(cp.starred)
            p.focus().refresh_html()
            Editor.$equation.focus()
            Figure.chart_size()
        })
        // clicking on a equation stack curve label
        $("#equation-stack").on("click", "> div", function(event) {
            /**
             * @type {Equation}
             */
            const equation = $(this).data("equation")
            if (event.target === $("span.remove", $(this))[0]) { // delete equation
                $(this).data("equation").remove()
            } else if (event.target === $("span.shown", $(this))[0]) { // toggle hide
                $(this).toggleClass("active", equation.active = !equation.active)
            } else {
                equation.focus()
                return
            }
            Editor.refresh()
        })
        // reset zoom ready
        $("#reset-zoom").on("click", "a", Figure.reset_zoom)

        // download PNG
        $("#export-image").click(() => {
            $("canvas").each(function() {
                exportCanvasAsPNG(this, $(this).data("figure").chart.options.title.text)
            })
        })
        // download CSV
        $("#export-csv").click(() => {
            $("canvas").each(function() {
                downloadFile(...$(this).data("prepared_export"))
            })
        })
        // download JSON
        $("#export-json").click(() => {
            $("canvas").each(function() {
                const f = $(this).data("figure")
                downloadFile(f.chart.options.title.text + ".json", f.export_json())
            })
        })

        // uncheck all
        $("#uncheck-all").click(() => {
            Territory.uncheck_all()
        })

        // refresh share menu
        $("#export input").change(Editor.refresh_export)
        $("#share-menu-button").click(Editor.refresh_export)
        $("#share-facebook").click(function() {
            window.open(this.href, 'facebookwindow', 'left=20,top=20,width=600,height=700,toolbar=0,resizable=1')
            return false
        })

        // copy input
        $(".copyinput").prop("readonly", true).click(function() {
            this.select()
        })


        // runtime
        Promise.all(source_data).then(() => {
            try {
                // we have to be sure another built is completed before processing tests_czech
                // => Territory.header is set and we know its beginning
                Territory.build_cz_tests(tests_czech)
            } catch (error) {
                console.error(error)
            }
            Territory.finalize()

            // Editor.setup options according to data boundaries
            const i = Territory.header.length - 1
            $("#day-range").data("ionRangeSlider").update({max: i, to: i})

            // build territories
            const $territories = $("#territories")
            /**
             *
             * @param {int} col_id
             * @param {Territory[]} storage
             */
            const td = (col_id, storage) => {
                const text = []
                const select_box = ["<option></option>"]
                for (const o of Object.values(storage)) {
                    text.push(...o.get_html())
                    select_box.push(`<option value="${o.dom_id}">${o.get_label()}</option>`)
                }
                $("> div:eq(" + col_id + ")", $territories)
                    .append(text.join(""))
                    .sorting("> div[data-sort]", "data-sort")
                $('#territory-add select').append(select_box) // add all territories to the search <select>
            }
            td(0, Territory.states)
            td(1, Territory.countries)
            td(1, Territory.regions)
            td(2, Territory.continents)
            Territory.world.eye() // world starts toggled
            $("> div", $territories).on("click", "> div", function(event) {
                // XXpossible performance issue: this event fires twice
                let highlight = true
                const t = Territory.get_by_dom_id($(this).attr("id"))
                if (event.target === $("span:eq(1)", $(this))[0]) { // un/star all
                    if (t.set_star(null) && !t.is_active) {
                        t.set_active()
                    }
                } else if (event.target === $("span:eq(2)", $(this))[0]) { // hide/show all
                    highlight = false // this has nothing common with current equation, do not highlight
                    t.toggle_children_visibility()
                } else if (event.target === $("span:eq(3)", $(this))[0]) { // un/check all
                    t.toggle_children_checked()
                } else if (event.target.type !== "checkbox") { // toggle clicked territory
                    $("input:checkbox", $(this)).click()
                } else {
                    t.set_active($(event.target).prop("checked"))
                }
                if (highlight) {
                    const $el = Equation.current.$element.addClass("highlight")
                    setTimeout(() => {
                        $el.removeClass("highlight")
                    }, 1000)
                }
                Editor.refresh()
            })
            // build territory select box
            $("#territory-add")
                .appendTo($("#territories > div:last-child"))
                .find("select")
                .chosen({"search_contains": true})
                .change(function() {
                    // toggle territory non/active and shows it
                    Territory.get_by_dom_id(this.value).set_active(null).$element.show()
                    $(this).val("").trigger("chosen:updated") // resets select to be empty
                    Editor.refresh(true)
                })
            $(document).bind('keydown', 'Alt+t', function() {
                // XX underline first letter so that user knows about the shortcut
                setTimeout(() => {
                    $("#territory-add select").trigger("chosen:open")
                }, 0)
            })

            // view menu
            const view_change = function() {
                // switches -> show/hide DOM sections
                // If it exists element with the same ID as the switch without "-switch", set this as data-target
                // Ex: #axes-options-switch toggles #axes-options
                const target = $(this).attr("data-target")
                let $el
                if (target) {
                    $el = $(target)
                } else {
                    $el = $("#" + $(this).attr("id").slice(0, -"-switch".length))
                }
                if ($el.length) {
                    $el.toggle($(this).prop("checked"))
                }

                // Checkbox inheritance, will show/hide children checkboxes
                // Checkbox is visible only if its data-parent is both checked and visible
                // ( = not hidden due to its data-parent)
                $("#setup [data-parent]").each(function() {
                    const $parent = $($(this).attr("data-parent"))
                    $(this).parent().toggle($parent.prop("checked") && $parent.parent().is(":visible"))

                })
            }
            $(".custom-control-input").change(view_change).each(view_change)


            // document events
            window.addEventListener('hashchange', () => {
                // console.log("HASH change event")
                Editor.load_hash()
            }, false)
            window.onpopstate = function() { // will probably trigger hashchange too
                // console.log("POPSTATE change event")
                Editor.load_hash()
            }
            window.addEventListener("resize", () => {
                Figure.chart_size()
            })
            Editor.dom_setup(false) // store default values from DOM to Editor.setup
            Editor.load_hash()


            // loading effect
            if (Editor.show_menu) { // show cases are shown instead of the editor
                Editor.show_menu_now()
                ready_to_refresh = false
                // even though all input triggered change event in load_hash,
                // if `main` is invisible, this had no effect
                // (ex: #view-menu child is not displayed if off even though its parent is on)
                $("#view-menu input").change()
                ready_to_refresh = true
            }
        })
    }

    /**
     * window.hash -> `Editor.setup` -> DOM
     * @returns {undefined}
     */
    ,
    // eslint-disable-next-line complexity
    load_hash: function() {
        // console.log("Load hash trying ...")
        try {
            // Try load Editor.setup from the window hash or page content
            const node = document.getElementById("chart")
            let hash = window.location.hash ?
                "{" + decodeURI(window.location.hash.substr(1)) + "}"
                : ((node && node.dataset.chart) ? node.dataset.chart : "{}")

            if (hash.length && hash[1] !== '"') {
                // this is not an old version bookmark that still uses double quotes in URL
                // this is current version bookmark that needs the single quotes to be converted to doubles
                const deserialize_chars = {"\"": "'", "'": '"', "%25": "%"}
                hash = hash.replace(/(')|(")|(%25)/g, m => deserialize_chars[m])
            }

            // console.log("Hash:", hash) // ," (having val: ", Editor.setup["outbreak-value"]);
            if (hash === just_stored_hash || hash === "{}") {
                // console.log("... nothing to load.")
                return
            }
            just_stored_hash = hash
            Editor.setup = JSON.parse(hash)
        } catch (e) {
            // console.warn("... cannot parse")
            return
        }
        // console.log("Parse Editor.setup:", Editor.setup)
        const original = ready_to_refresh // block many refreshes issued by every $el.change call
        ready_to_refresh = false
        for (const key in Editor.setup) {
            let val = Editor.setup[key]

            // handle keys that `refresh` does not handle: equations and chart-size
            if (key === "equations" || key === "figures") {
                continue // will be handled later since it is important figures are deserialized earlier
            } else if (key === "chart-size") {
                Figure.chart_size({"from": val})
            } else if (key === "iframe") {
                // we are in an iframe, hide all except figures
                Editor.iframe = true
                const width = Editor.setup["iframe-width"]

                setTimeout(() => { // i don't know much why, this works better if hiding postponed
                    $("body > *").hide()
                    $("html").css("margin-top", "0")
                    // show just the #canvas-container in the main contents
                    const $container = $("body > .container, body > main").show().css({
                        "max-width": "unset",
                        "min-width": "unset",
                        "width": width + "px"
                    })
                    $("div:not(#canvas-container), a, label, input", $container).hide() // hide all divs, show just some


                    if (Editor.setup["iframe-day-range"]) {
                        $("#day-range").show().parents().show()
                    }
                    if (Editor.setup["iframe-outbreak"]) {
                        $("#outbreak-threshold").show().parents().show()
                    }
                    if (Editor.setup["iframe-buttons"]) {
                        $("#export > a:not(#share-menu-button)").show().parents().show()
                    }
                    $("#canvas-container").css("width", width + "px")
                }, 0)
                continue
            }

            // key may be a DOM element too
            const $el = $("#" + key)
            if (!(key in Editor.setup) || !$el.length) {
                continue
            }
            const ion = $el.data("ionRangeSlider")
            if (ion) {
                if (key === "day-range") {
                    if (Number.isInteger(val)) {
                        // compatibility with the old format when day-range could store int only when single day
                        val = [val, ion.options.max, ion.options.max, 0]
                    }
                    // slider day-range is sometimes of type double and sometimes single,
                    // but still has values: [min, from, max, from_]
                    // while `max` is might not be the current day (already stored in slider)
                    // but the `max` day link was shared
                    // `from_` is the value we should use for `from` when turning off `single-day`
                    if (val[2] === val[1]) {
                        // if we shared day range till 10 and that was the maximum day,
                        // use current maximum day and shift the "from" day
                        if (val[0] !== 0) {
                            // ex: "from" day was "7 days ago" when link bookmarked,
                            // restore this to be "7 days ago" too
                            // XX I am not sure this is the expected behaviour.
                            // XX Sometimes you just want your chart to start the 1st Mar or something.
                            val[0] += ion.options.max - val[1]
                        }
                        val[1] = ion.options.max
                    }
                    ion.update({from: val[0], to: val[1], _from: val[3]})
                } else if (ion.options.type === "double") {
                    ion.update({from: val[0], to: val[1]})
                } else {
                    if (key === "x-axis") {
                        val = ["C", "R", "D", "T"].indexOf(val) > -1 ? val : null // XSS whitelist
                    }

                    if (!$el.data("has-bound-input")) {
                        ion.update({from: val})
                    }
                    continue // we will not trigger $el.change event because bound-$input will trigger it for us
                }
            } else if ($el.attr("type") === "checkbox" || $el.attr("type") === "radio") {
                $el.prop("checked", val)
            } else {
                $el.val(val)
            }

            $el.change()
        }

        // process parameters whose order is important
        let val
        if ((val = Editor.setup["figures"])) {
            Figure.deserialize(val)
        }
        if ((val = Editor.setup["equations"])) {
            Equation.deserialize(val)
        }

        ready_to_refresh = original
        Editor.refresh(false)
    }


    /*
     * DOM -> `Editor.setup`
     * @param {boolean} allow_window_hash_change
     * @returns {undefined}
     */
    ,
    dom_setup: function(allow_window_hash_change = true) {
        // read all DOM input fields
        $("#setup input:not(.nohash)").each(function() {
            // Load value from the $el to Editor.setup.
            const $el = $(this)
            const key = $el.attr("id")
            let val
            const ion = $el.data("ionRangeSlider")
            if (ion) {
                if (key === "day-range") {
                    // add maximum day so that we are able to update maximum day when shared
                    val = [ion.result.from, ion.result.to, ion.options.max, ion.options._from]
                } else if (key === "x-axis") {
                    val = {
                        [Figure.X_AXIS_C]: "C",
                        [Figure.X_AXIS_R]: "R",
                        [Figure.X_AXIS_D]: "D",
                        [Figure.X_AXIS_T]: "T",
                    }[Figure.X_AXIS[ion.result.from]]
                } else if (ion.options.type === "double") {
                    val = [ion.result.from, ion.result.to]
                } else {
                    val = ion.result.from
                }
            } else if ($el.attr("type") === "checkbox" || $el.attr("type") === "radio") {
                val = $el.prop("checked") ? 1 : 0
            } else {
                val = $el.val()
            }
            if (key === "axes-type") {
                $("#x-axis-container").toggle(val === Figure.TYPE_LOG_DATASET)
            }

            Editor.setup[key] = val
        })

        // convert global input fields to equation attributes
        // these parameters were handled by their respective object
        // more over, there is no need to save them in hash, they are reconstructed on (Figure|Equation).focus
        if (Equation.current) {
            Equation.current.dom_setup()
        }

        if (Figure.current) {
            Figure.current.dom_setup()
        }

        if (allow_window_hash_change) {
            Editor.save_hash()
        }
    }

    ,
    serialize: function(setup, store = false) {
        const s = JSON.stringify(setup)
        if (store) {
            just_stored_hash = s
        }
        const serialize_chars = {"\"": "'", "'": '"', "%": "%25"}
        const state = s.substring(1, s.length - 1).replace(/(')|(")|(%)/g, m => serialize_chars[m])
        return (edvard_deployment ? "" : "?") + "chart=" + Editor.chart_id + "#" + state
    }


    /**
     *
     * @param {Boolean|Event} event
     *      True -> make refresh possible further on. Do not change hash.
     *       False -> do not redraw outbreak_slider (which is currently moved)
     *       that would stop its movement. Do not change hash.
     *      Event -> callback from an input change, do change window hash.
     * @returns {Boolean}
     */
    ,
    refresh: function(event = null) {
        // pass only when ready
        if (event === true) {
            ready_to_refresh = true
        } else if (!ready_to_refresh) {
            return false
        }
        // assure `Editor.setup` is ready
        // refresh window.location.hash only if we came here through a DOM event,
        // not through load (event === false || true). We want to conserve Editor.chart_id in the hash
        // till the thumbnail can be exported if needed.
        Editor.dom_setup(typeof (event) !== "boolean")
        $("#export-data").html("") // reset export-data, will be refreshed in Figure.refresh/Figure.prepare_export


        // build chart data
        // process each country
        // XX we may refresh current figure only if not loading.
        // XX But that imply we have to set Outbreak and Days range independent.
        const boundaries = Object.values(Figure.figures).map(f => f.refresh())

        // XX all figures have the same outbreak and day range for now, this might be change
        if (event !== false) { // we can safely redraw outbreak slider
            let max = Math.max(...boundaries.map(i => i[1])) // boundary total max; max value amongst all datasets

            if (Editor.setup["outbreak-mode"]) { // population outbreak mode
                max = Math.min(max * 100000 / boundaries.map(i => i[2]).sum())
            }
            let values
            if (!isFinite(max)) { // all values can be NaN, ex: when toggling outbreak mode to population
                values = [1, 2, 3, 4, 5, 6, 7, 8, 9, Editor.setup["outbreak-value"]] // fallback emergency options
            } else {
                values = logslider(1, 100, 1, max)
            }

            if (max > 0) {
                // leave the last values, there are no data (too narrow date range or too high outbreak threshold)
                // otherwise the outbreak slider would become unusable with no available values
                $("#outbreak-threshold").data("values", values).data("ionRangeSlider").update({
                    values: values
                })
                Editor.set_slider($("#outbreak-threshold"), Editor.setup["outbreak-value"])
            }
        }

        // empty figures cleanup
        Figure.trailing_check()

        $("#export-link").val(window.location.href)
    }


    ,
    set_slider: function($slider, val, init_position = null) {
        //    console.log("Set slider", $slider.attr("id"), val, init_position);
        const r = $slider.data("ionRangeSlider")
        const o = {}
        if (init_position) {
            r.options.values = range(init_position)
            r.options.values[init_position] = val
            o["from"] = init_position
        } else if (r.options.values.length) {
            let index = 0
            for (const i in r.options.values) {
                if (val <= r.options.values[i]) { // this is the chosen position, slighly greater than the wanted value
                    index = i
                    break
                }
            }
            if (index >= r.options.values.length) { // we have not found the position - put there the greatest
                index = r.options.values.length
            }
            //        console.log("Changed value", r.options.values, index);
            r.options.values[index] = parseInt(val)
            //        console.log("Changed values:", r.options.values);
            o["from"] = index
        } else {
            o["from"] = val
            if (r.result.max < val) {
                o["max"] = val
            }
        }
        //    console.log("Updating", $slider.attr("id"), o);
        r.update(o)
    }

    ,
    export_thumbnail: function() {
        /*
         * No upload without authentication.
         *
        // resize the canvas and upload to the server
        if (!edvard_deployment || !Figure.figures.length) {
            // $("#export input").change will trigger this when still loading
            // and no refresh happenned (no canvas available)
            return;
        }
        console.log("Exporting thumbnail");
        $.ajax({
            url: window.location.pathname + "/upload", // /chart=HASH/upload
            method: "post",
            data: {"png": exportCanvasAsPNG(make_thumbnail($("canvas")[0]))}
        });
        */
    }

    ,
    refresh_export: function() {
        Editor.export_thumbnail()
        $("#share-facebook").attr("href", "http://www.facebook.com/sharer.php?u=" + window.location.href)
        const width = Editor.setup["iframe-width"] * Figure.figures.length
        const height = width / 2 + 10
            + 75 * Editor.setup["iframe-day-range"]
            + 75 * Editor.setup["iframe-outbreak"]
            + 30 * Editor.setup["iframe-buttons"]
        const setup = Object.assign({}, Editor.setup)
        setup["iframe"] = 1
        const src = encodeURI(window.location.origin + window.location.pathname + Editor.serialize(setup))
        $("#share-iframe").val(`<iframe width="${width}" height="${height}" frameborder="0" src="${src}"></iframe>`)
    }
}

document.addEventListener('DOMContentLoaded', Editor.init_editor) // Figure class must be ready
